import { useMemo } from "react";

import { Company, PaymentPlan } from "@elton/types";
import { Alert, AlertDescription, AlertIcon } from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";

import { useT } from "@transifex/react";

export const BusinessPlanWidget: React.FC<{
  className?: string;
  company: Company;
  plan: PaymentPlan | null;
}> = ({ className, company, plan }) => {
  const t = useT();

  const textContent = useMemo(() => {
    if (plan) {
      if (plan.name === "Elton Enterprise") {
        return null;
      }

      return t(
        "{companyName} is currently on {paymentPlan} plan. Unlock features and additional user seats by upgrading to one of our premium plans.",
        {
          companyName: company.name,
          paymentPlan: plan.name,
        },
      );
    }

    return t(
      "{companyName} is currently on not on a business plan. Unlock features and additional user seats by upgrading to one of our premium plans.",
      {
        companyName: company.name,
      },
    );
  }, [t, company, plan]);

  return (
    <>
      {textContent && (
        <Alert className={cn(className, "flex items-center gap-4")}>
          <AlertIcon />
          <AlertDescription>{textContent}</AlertDescription>
        </Alert>
      )}
    </>
  );
};
