import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@elton/ui/core";
import { ZapIcon } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";
import { MainNavigation } from "@elton/ui/system";

import { ThemeDropdown } from "@/components/dropdowns/theme-dropdown/theme-dropdown";
import { UserDropdown } from "@/components/dropdowns/user-dropdown/user-dropdown";
import { HomeLink } from "@/components/links/home-link/home-link";

import { useNavigationItems } from "./hooks/use-navigation-items";
import { useUpgradeEmailLink } from "./hooks/use-upgrade-email-link";

import { T } from "@transifex/react";
import { UpdatesDropdown } from "@/components/dropdowns/updates-dropdown/updates-dropdown.tsx";

export const DashboardHeader: React.FC = () => {
  const navItems = useNavigationItems();
  const emailLink = useUpgradeEmailLink();

  return (
    <header className="flex border-b pb-4 w-full items-center">
      <HomeLink className="text-primary mr-6" />
      <MainNavigation items={navItems} />
      <div className="md:ml-auto ml-4 items-center hidden md:flex">
        <NavigationMenu>
          <NavigationMenuList className="gap-2">
            <UpdatesDropdown />
            <NavigationMenuItem className="cursor-pointer">
              <a
                className={cn(
                  "inline-flex gap-2",
                  navigationMenuTriggerStyle(),
                  "bg-[#fae8ff] hover:bg-[#f5d0fe] text-[#a21caf] hover:text-[#a21caf]",
                )}
                href={emailLink}
                target="_blank"
                id="upgrade-link"
                referrerPolicy="no-referrer"
              >
                <ZapIcon className="w-4 h-auto stroke-[#a21caf] fill-[#a21caf]" />
                <span>
                  <T _str="Upgrade" />
                </span>
              </a>
            </NavigationMenuItem>
            <UserDropdown />
            <ThemeDropdown />
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </header>
  );
};
