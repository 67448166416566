import { z } from "zod";

const AddressSchema = z.object({
  landkode: z.string(),
  land: z.string(),
  poststed: z.string(),
  adresse: z.array(z.string()),
  postnummer: z.string(),
});

export const EnhetSchema = z.object({
  organisasjonsnummer: z.string(),
  navn: z.string(),
  postadresse: AddressSchema.optional(),
  forretningsadresse: AddressSchema.optional(),
  epostadresse: z.string().email().optional(),
  telefon: z.string().optional(),
  mobil: z.string().optional(),
});

// Infer TypeScript type from the schema
export type Enhet = z.infer<typeof EnhetSchema>;
