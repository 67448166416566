import { Locale } from "@elton/types";
import {
  Button,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  Separator,
} from "@elton/ui/core";

import { useLocalization } from "@/context/localization-context/use-localization";
import { useRole } from "@/context/role-context/use-role";
import { Theme } from "@/context/theme-context/theme-context";
import { useTheme } from "@/context/theme-context/use-theme";
import { T } from "@transifex/react";

export const SettingsPage: React.FC = () => {
  const { theme, setTheme } = useTheme();
  const { role } = useRole();
  const localization = useLocalization();

  return (
    <div className="space-y-6 p-10 pb-16">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">
          <T _str="Settings" />
        </h2>
        <p className="text-muted-foreground">
          <T _str="Manage your account settings and set e-mail preferences." />
        </p>
      </div>
      <Separator />
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium">
            <T _str="Appearance" />
          </h3>
          <p className="text-sm text-muted-foreground">
            <T _str="Customize the appearance of the app. Automatically switch between themes." />
          </p>
        </div>
        <form>
          <Label>
            <T _str="Theme" />
          </Label>
          <p className="text-[0.8rem] text-muted-foreground mb-2">
            <T _str="Select the theme for the dashboard." />
          </p>
          <Select onValueChange={(value) => setTheme(value as Theme)}>
            <SelectTrigger className="w-[200px]">
              <span className="capitalize">{theme}</span>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="light">
                  <T _str="Light" />
                </SelectItem>
                <SelectItem value="dark">
                  <T _str="Dark" />
                </SelectItem>
                <SelectItem value="system">
                  <T _str="System" />
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </form>
        <Separator />
        <div>
          <h3 className="text-lg font-medium">
            <T _str="Localization" />
          </h3>
          <p className="text-sm text-muted-foreground">
            <T
              _str="Customize the localization of the app. Automatically switch between
            languages."
            />
          </p>
        </div>
        <form>
          <Label>
            <T _str="Language" />
          </Label>
          <p className="text-[0.8rem] text-muted-foreground mb-2">
            <T _str="Select the language for the dashboard." />
          </p>
          <Select onValueChange={(value) => localization.setLocale(value as Locale)}>
            <SelectTrigger className="w-[200px]">
              <span className="capitalize">
                {
                  localization.languages?.find((lan) => lan.code === localization.locale)
                    ?.localized_name
                }
              </span>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {localization.languages?.map((value, i) => (
                  <SelectItem key={i} value={value.code}>
                    {value.localized_name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </form>
        {role === "superadmin" && (
          <>
            <Separator />
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-medium">
                  <T _str="Sentry" />
                </h3>
                <p className="text-sm text-muted-foreground">
                  <T _str="Trigger a Sentry error (Only for testing)" />
                </p>
              </div>
              {true && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    throw new Error("Sentry Test Error");
                  }}
                >
                  Trigger error
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
