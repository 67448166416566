import { createFileRoute, redirect } from "@tanstack/react-router";

import { LoginPage } from "@/pages/login-page/login-page";

import { kindeClient } from "@/api/clients/kinde-client";

export const Route = createFileRoute("/login")({
  beforeLoad: async () => {
    if (await kindeClient.getToken()) {
      throw redirect({ to: "/", params: {} });
    }
  },
  validateSearch: (search: Record<string, unknown>) => {
    return {
      login_hint: (search.login_hint as string) || "",
    };
  },
  component: LoginPage,
});
