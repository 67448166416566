import { createFileRoute, redirect } from "@tanstack/react-router";

import { kindeClient } from "@/api/clients/kinde-client";
import { getCompanies } from "@/api/requests/companies/get-companies";

export const Route = createFileRoute("/")({
  loader: async () => {
    const token = await kindeClient.getToken();

    if (!token) {
      console.log("Auth token missing. Redirecting to login");
      throw redirect({ to: "/login", search: { login_hint: "" } });
    }

    const firstCompanies = await getCompanies({
      limit: 1,
      offset: 0,
      sort: "",
      filter: "",
    });

    if (firstCompanies.data.length > 0) {
      throw redirect({
        to: `/companies/${firstCompanies.data[0]!.id}`,
      });
    }

    throw redirect({
      to: "/companies",
      search: { filter: "", limit: 10, sort: "", offset: 0 },
    });
  },
});
