import React from "react";
import { Link } from "@tanstack/react-router";

import { Button, Sheet, SheetContent, SheetFooter, SheetTrigger } from "@elton/ui/core";
import { LogOut, Menu, Settings } from "@elton/ui/icons";

import { cn } from "@ui/lib/utils";

import { UserProfileAvatar } from "@/components/dropdowns/user-dropdown/components/user-profile-avatar";
import { UserProfileDetails } from "@/components/dropdowns/user-dropdown/components/user-profile-details";

import { MainNavigationProps } from "../main-navigation-types";

import { useRole } from "@/context/role-context/use-role";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { T } from "@transifex/react";
import { UpdatesDropdown } from "@/components/dropdowns/updates-dropdown/updates-dropdown.tsx";

export const MainNavigationSmall: React.FC<MainNavigationProps> = ({ className, items }) => {
  const { user } = useKindeAuth();
  const { role } = useRole();

  return (
    <div className={cn("flex", className)}>
      <Sheet>
        <SheetTrigger className="active:opacity-10 transition-opacity">
          <div className={cn("flex items-center gap-2 p-2 outline-none")}>
            <UpdatesDropdown />
            <Menu className="w-6 h-auto" />
          </div>
        </SheetTrigger>
        <SheetContent className="flex flex-col">
          <nav className="flex-1">
            <ul className="flex flex-col">
              {items.map((rootLink, i) => (
                <li key={i}>
                  <a
                    href={rootLink.href}
                    className={cn(ClassNames.LinkCommon, ClassNames.LinkRoot)}
                  >
                    {rootLink.title}
                  </a>
                  {rootLink.items?.length && (
                    <ul className="flex flex-col">
                      {rootLink.items.map((subLink, j) => (
                        <li key={j}>
                          <a
                            href={subLink.href}
                            className={cn(ClassNames.LinkCommon, ClassNames.LinkSub)}
                          >
                            {subLink.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <SheetFooter className="flex sm:flex-col sm:justify-start">
            <div className="flex flex-row items-center gap-2">
              <UserProfileAvatar user={user} />
              <UserProfileDetails user={user} role={role} />
            </div>

            <div className="mt-4 space-x-2">
              <Button variant="ghost" asChild>
                <Link to="/logout">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>
                    <T _str="Log out" />
                  </span>
                </Link>
              </Button>
              <a
                href="/settings"
                className="p-2 rounded inline-flex items-center hover:bg-accent hover:text-accent-foreground"
              >
                <Settings className="mr-2 h-4 w-4" />
                <T _str="Settings" />
              </a>
            </div>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};

const ClassNames = {
  LinkCommon:
    "inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 font-medium",
  LinkRoot: "text-sm",
  LinkSub: "text-xs",
};
