import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@elton/ui/core";
import { Megaphone } from "@elton/ui/icons";

import { useEffect, useState } from "react";
import { T } from "@transifex/react";
import { getPreferredLocale } from "@/context/localization-context/localization.ts";
import { sleep } from "@transifex/native";
import PostHogAdapter from "@/adapters/posthog-adapter.ts";

export const PRODUCT_UPDATES = [
  {
    id: "1",
    title: <T _str="New: Charge at Mer with Elton!" />,
    description: (
      <T _str="We are expanding coverage across all of Norway, and now you can charge at 4,600 Mer charging points with Elton ⚡️" />
    ),
    date: "2025-02-05",
  },
  {
    id: "2",
    title: <T _str="New: Charge at Tesla with Elton!" />,
    description: (
      <T _str="You can now use Elton as payment method when charging at Tesla stations⚡️" />
    ),
    date: "2025-02-10",
  },
].sort((a, b) => b.id.localeCompare(a.id));
export const UpdatesDropdown: React.FC = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [readPosts, setReadPosts] = useState<string[]>([]);

  const locale = getPreferredLocale("en");

  // Load read posts from localStorage on mount
  useEffect(() => {
    const savedReadPosts = localStorage.getItem("readProductUpdates");
    if (savedReadPosts) {
      setReadPosts(JSON.parse(savedReadPosts));
    }
  }, []);

  // Calculate unread count whenever readPosts changes
  useEffect(() => {
    const unreadUpdates = PRODUCT_UPDATES.filter((update) => !readPosts.includes(update.id)).length;
    setUnreadCount(unreadUpdates);
  }, [readPosts]);

  const handleUpdateClick = () => {
    sleep(3000).then(() => {
      setReadPosts(PRODUCT_UPDATES.map((update) => update.id));
      localStorage.setItem("readProductUpdates", JSON.stringify(readPosts));
    });
    PostHogAdapter.logEvent("updates_opened", { readPosts });
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString(locale, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <>
      <DropdownMenu onOpenChange={() => handleUpdateClick()}>
        <DropdownMenuTrigger className="flex items-center outline-none">
          <div className="relative p-2 rounded-full  hover:bg-accent">
            <Megaphone className="w-6 h-6" />
            {unreadCount > 0 && (
              <span className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full text-white text-xs flex items-center justify-center">
                {unreadCount}
              </span>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <div className="max-h-96 w-[95vw] md:w-[500px] overflow-y-auto">
            {PRODUCT_UPDATES.map((update) => (
              <div
                key={update.id}
                className={`p-4 border-b last:border-b-0 cursor-pointer transition-colors ${
                  readPosts.includes(update.id) ? "text-muted-foreground" : ""
                }`}
              >
                <div className="flex justify-between items-center mb-1 gap-2">
                  <h3 className="font-medium max-w-[350px]">{update.title}</h3>
                  <span className="text-xs">{formatDate(update.date)}</span>
                </div>
                <p className="text-sm">{update.description}</p>
              </div>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
