import { List, ListCompany } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";
import { DefaultListParams } from "@/api/params/default-list-params";

export const getCompanies = async (params?: DefaultListParams) => {
  const response = await getServicesClient().get<List<ListCompany>>(`/companies`, { params });

  return response.data;
};
