import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { List, ListDepartment } from "@elton/types";

import { DepartmentsPage } from "@/pages/departments-page/departments-page";

import { defaultListParamsSchema } from "@/api/params/default-list-params";
import { getDepartments } from "@/api/requests/departments/get-departments";

const SearchParamsSchema = defaultListParamsSchema.extend({
  open: z.string().optional(),
});

export type DepartmentsSearchParams = z.infer<typeof SearchParamsSchema>;

export const Route = createFileRoute("/_authenticated/companies/$companyId/_dashboard/departments")(
  {
    component: DepartmentsPage,
    validateSearch: SearchParamsSchema,
    loaderDeps: (opts) => SearchParamsSchema.parse(opts.search),
    loader: async ({
      params,
      deps,
    }): Promise<{
      departments: List<ListDepartment>;
      companyId: string;
    }> => {
      return {
        companyId: params.companyId,
        departments: await getDepartments(params.companyId, deps),
      };
    },
  },
);
