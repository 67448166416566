import type { FieldApi } from "@tanstack/react-form";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: FieldApi<any, any, any, any>;
}

export const FieldInfo: React.FC<IProps> = ({ field }) => {
  return (
    <div>
      {field.state.meta.isTouched && field.state.meta.errors.length ? (
        <em className="text-destructive text-sm">{field.state.meta.errors.join(", ")}</em>
      ) : null}
    </div>
  );
};
