import React from "react";

import { ChildCompany } from "@elton/types";
import { defaultCreateChildCompany } from "@elton/types/mocks";

import { CreateChildCompanyForm } from "@/components/forms/child-company-form/default/create-child-company-form.tsx";
import { CompanyTable } from "@/components/tables/company-table/company-table.tsx";

import { useCreateChildCompanyMutation } from "@/api/mutations/child-company/create-child-company-mutation.tsx";
import { useDeleteChildCompanyMutation } from "@/api/mutations/child-company/delete-child-company-mutation.tsx";
import { DefaultListParams } from "@/api/params/default-list-params.ts";
import { Route as ChildCompaniesRoute } from "@/routes/_authenticated/companies/$companyId/_dashboard/child-companies";
import { useT } from "@transifex/react";

export const ChildCompaniesPage: React.FC = () => {
  const t = useT();
  const loaderData = ChildCompaniesRoute.useLoaderData();
  const searchParams = ChildCompaniesRoute.useSearch<DefaultListParams>();
  const parentCompanyId = ChildCompaniesRoute.useParams().companyId;

  const createChildCompany = useCreateChildCompanyMutation(parentCompanyId);
  const deleteChildCompany = useDeleteChildCompanyMutation(parentCompanyId);

  return (
    <CompanyTable<ChildCompany>
      title={t("Branch companies")}
      description={t(
        "Manage all your branch companies - Click a company to inspect or make changes.",
      )}
      buttonTitle={t("Create branch company")}
      fullPath={ChildCompaniesRoute.fullPath}
      companies={loaderData}
      createCompany={createChildCompany}
      deleteCompany={deleteChildCompany}
      searchParams={searchParams}
      defaultCreateCompany={defaultCreateChildCompany}
      CreateCompanySheet={({ createCompany, formData, handleCreateCompany }) => (
        <CreateChildCompanyForm
          loading={createCompany.isPending}
          data={formData}
          onCreate={handleCreateCompany}
          onUpdate={() => {}}
        />
      )}
    />
  );
};
