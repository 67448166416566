import { RegisteredRouter } from "@tanstack/react-router";

import { kindeClient } from "@/api/clients/kinde-client";
import * as Sentry from "@sentry/react";

let hasAuthenticatedOnce = false;

const init = (router: RegisteredRouter) => {
  if (!import.meta.env.PROD) {
    console.warn("Sentry is disabled in development mode");
    return;
  }

  Sentry.init({
    dsn: "https://8292be987b49104fd82eb5e27e624b42@o4507904859176960.ingest.de.sentry.io/4508647574929488",
    integrations: [Sentry.tanstackRouterBrowserTracingIntegration(router)],
    // Setting a sample rate is required for sending performance data.
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control.
    tracesSampleRate: 1.0,
  });
};

const setUser = async () => {
  if (hasAuthenticatedOnce) {
    return;
  }

  const user = await kindeClient.getUser();

  if (!Sentry.isInitialized()) {
    if (import.meta.env.PROD) console.warn("Sentry is not initialized. Could not set user.");
    return;
  }

  if (!user || !user.id) {
    console.warn("Sentry is not able to set user. Could not retrieve an authenticated user.");
    return;
  }

  hasAuthenticatedOnce = true;
  Sentry.setUser({ id: user.id });
};

const captureException = (err: Error) => {
  if (!Sentry.isInitialized()) {
    console.warn("Sentry is not initialized. Could not capture exception.");
    console.error(err);
    return;
  }

  Sentry.captureException(err);
};

const SentryAdapter = {
  init,
  setUser,
  captureException,
};

export default SentryAdapter;
