import { forwardRef } from "react";
import { FieldApi } from "@tanstack/react-form";

import { Input, Label } from "@elton/ui/core";
import { Asterisk } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { FieldInfo } from "./field-info/field-info";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TextFieldProps = FieldApi<any, any, any, any, any>;
type Ref = HTMLInputElement;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  field: TextFieldProps;
  label: string;
  className?: string;
}

export const InputField = forwardRef<Ref, InputProps>(
  ({ field, label, className, ...rest }, ref) => {
    return (
      <div className={cn("space-y-2", className)}>
        <Label>{label}</Label>
        <div className="relative">
          <Input
            ref={ref}
            variant={field.state.meta.errors.length ? "destructive" : "default"}
            id={field.name}
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            {...rest}
          />
          {rest.required && !field.state.value && (
            <Asterisk className="w-3 absolute top-0 right-0 h-full mr-2" />
          )}
        </div>
        <FieldInfo field={field} />
      </div>
    );
  },
);
