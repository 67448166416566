import { createContext, useEffect, useState } from "react";

import { Locale, supportedLanguages, TransifexLanguage } from "@elton/types";

import { getPreferredLocale } from "./localization";

import { useTX } from "@transifex/react";

type LocalizationProviderProps = {
  children: React.ReactNode;
  defaultLocale?: Locale;
  storageKey?: string;
};

type LocalizationProviderState = {
  languages: TransifexLanguage[];
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

const initialState: LocalizationProviderState = {
  languages: [],
  locale: "no",
  setLocale: () => null,
};

/**
 * Context for the locale provider.
 */
export const LocalizationProviderContext = createContext<LocalizationProviderState>(initialState);

/**
 * Provides a locale context for the application.
 *
 * @param children - The child components to be wrapped by the locale provider.
 * @param defaultLocale - The default locale to be used if no locale is set.
 * @param storageKey - The key used to store the locale in local storage.
 *
 * @returns The locale provider component.
 */
export function LocalizationProvider({
  children,
  defaultLocale = "no",
  storageKey = "elton-portal-locale",
}: LocalizationProviderProps) {
  const [locale, setLocale] = useState<Locale>(
    (window.localStorage.getItem(storageKey) as Locale) ?? getPreferredLocale(defaultLocale),
  );

  const tx = useTX({ currentLocale: locale });

  useEffect(() => {
    tx.setCurrentLocale(locale);
  }, [locale, tx]);

  const value: LocalizationProviderState = {
    languages: supportedLanguages,
    locale,
    setLocale: (locale: Locale) => {
      localStorage.setItem(storageKey, locale);
      setLocale(locale);
    },
  };

  return (
    <LocalizationProviderContext.Provider value={value}>
      {children}
    </LocalizationProviderContext.Provider>
  );
}
