import { CreateDepartment } from "@elton/types";
import {
  AlertDialogTrigger,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  SheetTrigger,
} from "@elton/ui/core";
import { ClipboardCopy, Edit, MoreHorizontal, Trash } from "@elton/ui/icons";

import { T } from "@transifex/react";

interface IProps {
  data: CreateDepartment;
  onUpdate: (value: CreateDepartment) => void;
  onDelete: (value: CreateDepartment) => void;
  onCopyId: (value: string) => void;
  className?: string;
}

export const DepartmentDropdownActions: React.FC<IProps> = ({
  data,
  onUpdate,
  onDelete,
  onCopyId,
  className,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className={className}>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">
              <T _str="Open menu" />
            </span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          <T _str="Actions" />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="gap-2" onClick={() => onCopyId(data.id!)}>
          <ClipboardCopy className="w-3 h-auto" />
          <span>
            <T _str="Copy ID" />
          </span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <SheetTrigger asChild>
          <DropdownMenuItem className="gap-2" onClick={() => onUpdate(data)}>
            <Edit className="w-3 h-auto" />
            <span>
              <T _str="Edit department" />
            </span>
          </DropdownMenuItem>
        </SheetTrigger>
        <AlertDialogTrigger asChild>
          <DropdownMenuItem className="text-destructive gap-2" onClick={() => onDelete(data)}>
            <Trash className="w-3 h-auto" />
            <span>
              <T _str="Delete department" />
            </span>
          </DropdownMenuItem>
        </AlertDialogTrigger>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
