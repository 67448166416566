import { z } from "zod";
import { invoiceCountryCodes } from "./invoicing";
import { validatePhoneNumber } from "../../validators/validate-phone-number";
import { billingStrategies } from "./billing-strategy";
import { CountryEnum } from "../common/country";

export type CreateCompany = z.infer<typeof createCompanySchema>;
export type Company = z.infer<typeof companyFullSchema>;
export type UpdateCompany = z.infer<typeof updateCompanySchema>;
export type CreateCompanyAndAdmin = z.infer<typeof createCompanyAndAdminSchema>;

const companyFullSchema = z.object({
  id: z.string(),
  customerNumber: z.string(),
  name: z.string(),
  organizationNumber: z.string(),
  address: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  contactName: z.string().optional(),
  contactPhone: z.string().optional(),
  contactEmail: z.string().email().optional(),
  externalInvoiceId: z.string().optional(),
  invoiceEmail: z.string().email().optional(),
  billingStrategy: billingStrategies.optional(),
  invoicePaymentTime: z.number().int().optional(),
  discountPercentage: z.number().min(0).max(100).optional(),
  internalNote: z.string().optional(),
  invoicingReferences: z
    .object({
      countryCode: invoiceCountryCodes,
      reference: z.string(),
    })
    .optional(),
  canBeParent: z.boolean(),
  parentCompanyId: z.string().optional(),
});

export const updateCompanySchema = companyFullSchema.extend({
  paymentPlanId: z.string().optional(),
  userLimit: z.number().optional(),
  monthlyCost: z.number().optional(),
});

export const createCompanySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  organizationNumber: z.string().min(1, { message: "Organization number is required" }),
  address: z.string().min(1, { message: "Street address is required" }),
  postalCode: z.string().min(1, { message: "Postal code is required" }),
  city: z.string().min(1, { message: "City is required" }),
  country: CountryEnum,
  contactName: z.string().min(1, { message: "Contact name is required" }),
  contactPhone: z.string().transform(validatePhoneNumber).optional(),
  contactEmail: z.string().email({ message: "Must be a valid email adress" }),
  externalInvoiceId: z.string().optional(),
  invoiceEmail: z.string().email({ message: "Must be a valid email adress" }),
  invoicePaymentTime: z.number().optional(),
  discountPercentage: z.number().optional(),
  internalNote: z.string().optional(),
  invoicingReferences: z
    .object({
      countryCode: invoiceCountryCodes,
      reference: z.string().optional(),
    })
    .optional(),
  canBeParent: z.boolean(),
  parentComanyId: z.string().optional(),
  paymentPlanId: z.string().optional(),
  userLimit: z.number().optional(),
  monthlyCost: z.number().optional(),
});

export const createCompanyAndAdminSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  organizationNumber: z.string().min(1, { message: "Organization number is required" }),
  address: z.string().min(1, { message: "Street address is required" }),
  postalCode: z.string().min(1, { message: "Postal code is required" }),
  city: z.string().min(1, { message: "City is required" }),
  country: CountryEnum,
  contactName: z.string().min(1, { message: "Contact name is required" }),
  contactPhone: z.string().transform(validatePhoneNumber).optional(),
  contactEmail: z.string().email({ message: "Must be a valid email adress" }),
  externalInvoiceId: z.string().optional(),
  invoiceEmail: z.string().email({ message: "Must be a valid email adress" }),
  invoicePaymentTime: z.number().optional(),
  discountPercentage: z.number().optional(),
  internalNote: z.string().optional(),
  invoicingReferences: z
    .object({
      countryCode: invoiceCountryCodes,
      reference: z.string().optional(),
    })
    .optional(),
  canBeParent: z.boolean(),
  parentComanyId: z.string().optional(),
  paymentPlanId: z.string().optional(),
  userLimit: z.number().optional(),
  monthlyCost: z.number().optional(),
  adminFirstName: z.string().min(1, { message: "Admin First name is required" }),
  adminLastName: z.string().min(1, { message: "Admin Last name is required" }),
  adminEmail: z.string().email({ message: "Must be a valid email adress (admin)" }),
});

export const companyLimitedSchema = companyFullSchema.omit({
  externalInvoiceId: true,
  internalNote: true,
});
