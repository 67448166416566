import { format } from "date-fns";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

import { formatCentsToDollars } from "@elton/utils";

export const CustomTooltip: React.FC<
  TooltipProps<ValueType, NameType> & { attributes?: string[] }
> = ({ active, payload, label, attributes }) => {
  if (active && payload && payload[0]) {
    const currentPayload = payload[0].payload;

    if (!currentPayload) {
      return null;
    }

    const entries = Object.entries(currentPayload).filter(
      ([key]) => attributes?.includes(key) ?? true,
    );

    return (
      <div className="bg-popover p-4 rounded-md text-primary flex flex-col min-w-40 shadow-md">
        <div className="space-y-1 mb-2">
          <h4 className="text-sm font-medium leading-tight">{label}</h4>
          <p className="text-xs font-normal leading-tight text-muted-foreground">
            {format(new Date(label), "EEEE")}
          </p>
        </div>
        {entries.map(([key, value], i) => (
          <dl key={i} className="flex justify-between items-center gap-1">
            <dt className="text-xs font-normal leading-tight uppercase">{key}</dt>
            <dd className="text-sm font-medium leading-tight">
              {formatCentsToDollars(value as number)}
            </dd>
          </dl>
        ))}
      </div>
    );
  }

  return null;
};
