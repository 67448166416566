import { CreateChildCompany, createChildCompanySchema } from "@elton/types";

import { SheetClose, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "@ui/index.tsx";

import { LoadingButton } from "@/components/buttons/loading-button";
import { InputField } from "@/components/fields/input-field";
import { useCreateChildCompanyForm } from "@/components/forms/child-company-form/default/use-create-company-form.tsx";
import { TFormAction } from "@/components/forms/form-types.ts";

import { T, useT } from "@transifex/react";

interface IProps {
  data: CreateChildCompany;
  onCreate: (data: CreateChildCompany) => void;
  onUpdate: (data: CreateChildCompany) => void;
  loading?: boolean;
}

export const CreateChildCompanyForm: React.FC<IProps> = ({ data, onCreate, onUpdate, loading }) => {
  const t = useT();
  const form = useCreateChildCompanyForm({
    data,
    onSubmit: (company: CreateChildCompany, action: TFormAction) => {
      if (action === "create") {
        onCreate(company);
      } else {
        onUpdate(company);
      }
    },
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    void form.handleSubmit();
  };

  return (
    <form.Subscribe
      selector={() => {
        return ["create"];
      }}
      children={([action]) => (
        <>
          <SheetHeader>
            <SheetTitle>
              {action === "create" ? t("Create branch company") : t("Update branch company")}
            </SheetTitle>
            <SheetDescription>
              <T _str="Create a new branch company - Click the button when you're done." />
            </SheetDescription>
          </SheetHeader>
          <form className="grid grid-cols-12 gap-6 text-primary">
            <form.Field
              name="name"
              validators={{
                onBlur: createChildCompanySchema.shape.name,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Company Name")}
                  placeholder={t("My company")}
                  required={true}
                />
              )}
            />
            <form.Field
              name="organizationNumber"
              validators={{
                onBlur: createChildCompanySchema.shape.organizationNumber,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Organisation Number")}
                  placeholder="xxxxxxxxx"
                  required={true}
                />
              )}
            />
            <form.Field
              name="address"
              validators={{
                onBlur: createChildCompanySchema.shape.address,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Street Address")}
                  placeholder="Edvard Storms gate 2"
                />
              )}
            />
            <form.Field
              name="postalCode"
              validators={{
                onBlur: createChildCompanySchema.shape.postalCode,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Postal Code")}
                  placeholder="0166"
                />
              )}
            />
            <form.Field
              name="city"
              validators={{
                onBlur: createChildCompanySchema.shape.city,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("City")}
                  placeholder="Oslo"
                />
              )}
            />
            <form.Field
              name="country"
              validators={{
                onBlur: createChildCompanySchema.shape.country,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Country")}
                  placeholder="Norway"
                />
              )}
            />
            <form.Field
              name="contactName"
              validators={{
                onBlur: createChildCompanySchema.shape.contactName,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Contact Name")}
                  placeholder="John Smith"
                />
              )}
            />
            <form.Field
              name="contactPhone"
              validators={{
                onBlur: createChildCompanySchema.shape.contactPhone,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Contact Phone")}
                  placeholder="+47xxxxxxxx"
                />
              )}
            />
            <form.Field
              name="contactEmail"
              validators={{
                onBlur: createChildCompanySchema.shape.contactEmail,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Contact Email")}
                  placeholder="contact@mycompany.com"
                />
              )}
            />
            <SheetFooter className="col-span-full">
              <SheetClose asChild>
                <LoadingButton
                  loading={loading}
                  onClick={handleFormSubmit}
                  type="submit"
                  id="create-company"
                >
                  {action === "create" ? t("Create") : t("Update")}
                </LoadingButton>
              </SheetClose>
            </SheetFooter>
          </form>
        </>
      )}
    />
  );
};
