import { createFileRoute, redirect } from "@tanstack/react-router";

import { PageLayout } from "@/pages/_layouts/page-layout/page-layout";
import { ErrorPage } from "@/pages/error-page/error-page";

import PostHogAdapter from "@/adapters/posthog-adapter.ts";
import SentryAdapter from "@/adapters/sentry-adapter";
import { kindeClient } from "@/api/clients/kinde-client";

const requireAuth = async (ctx: Context) => {
  if (!(await kindeClient.getToken())) {
    throw redirect({ to: "/login", search: { login_hint: "" } });
  }
  await SentryAdapter.setUser();

  if (ctx.cause === "stay") PostHogAdapter.logPageView(ctx.location);
};

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: requireAuth,
  component: PageLayout,
  errorComponent: ErrorPage,
});

type Context = {
  location: {
    pathname: string;
    search: Record<string, unknown>;
    searchStr: string;
    hash: string;
    href: string;
  };
  cause: "stay" | "preload" | "enter";
};
