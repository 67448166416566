import { UseMutationOptions } from "@tanstack/react-query";

import {
  createSessionsMasterExport,
  MutationFnParams as CreateMasterExportParams,
} from "../requests/exports/create-master-sessions-export";
import {
  createSessionsExport,
  MutationFnParams as CreateSessionsExportParams,
} from "../requests/exports/create-sessions-export";

export const createSessionsExportOptions = (
  companyId: string,
): UseMutationOptions<CreateSessionsExportParams, Error, CreateSessionsExportParams> => ({
  mutationFn: async (data: CreateSessionsExportParams) => createSessionsExport(companyId, data),
});

export const createSessionsMasterExportOptions = (): UseMutationOptions<
  CreateMasterExportParams,
  Error,
  CreateMasterExportParams
> => ({
  mutationFn: async (data: CreateMasterExportParams) => createSessionsMasterExport(data),
});
