import { useMemo } from "react";

import { Currency, SpendingsSequence } from "@elton/types";
import { Card, CardContent, CardHeader, CardTitle } from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";
import { formatCentsToDollars } from "@elton/utils";

import { BarChart } from "@/components/charts/bar-chart/bar-chart";
import { BarColorByCurrency } from "@/components/charts/bar-chart/bar-chart-colors";
import { CurrencyDropdown } from "@/components/dropdowns/currency-dropdown/currency-dropdown";

import { useSelector } from "@/hooks/select/use-selector";

import { T, useT } from "@transifex/react";

interface IProps {
  data: SpendingsSequence[];
  acc: Record<Currency, number>;
}

export const SpendingsWidget: React.FC<IProps> = ({ data, acc }) => {
  const t = useT();

  const currencySelector = useSelector<Currency>({
    NOK: acc.NOK > -1,
    SEK: acc.SEK > -1,
    DKK: acc.DKK > -1,
    EUR: acc.DKK > -1,
  });

  const yAxisKeys = useMemo(() => {
    return Object.entries(currencySelector.data)
      .filter(([, val]) => val === true)
      .map((entry) => entry[0].toUpperCase() as string);
  }, [currencySelector.data]);

  return (
    <Card>
      <CardHeader className="flex flex-row justify-between space-y-0">
        <div className="space-y-2">
          <CardTitle className="text-sm font-medium leading-tight">
            <T _str="Monthly spending" />
          </CardTitle>
          <p className="text-xs text-muted-foreground">
            <span className="text-green-700">+1% </span>
            <T _str="from last month" />
          </p>
        </div>
        <CurrencyDropdown currencies={currencySelector.data} onSelect={currencySelector.select} />
      </CardHeader>
      <CardContent className="space-y-6 flex flex-col items-center">
        <div className="h-60 sm:h-[26rem] w-full">
          <BarChart data={data} xAxisKey="date" yAxisKeys={yAxisKeys} />
        </div>
        <div className="flex gap-8">
          <DescriptionList
            title={t("NOK")}
            value={`kr ${formatCentsToDollars(acc.NOK)}`}
            color={BarColorByCurrency.NOK}
          />
          <DescriptionList
            title={t("SEK")}
            value={`kr ${formatCentsToDollars(acc.SEK)}`}
            color={BarColorByCurrency.SEK}
          />
          <DescriptionList
            title={t("DKK")}
            value={`kr ${formatCentsToDollars(acc.DKK)}`}
            color={BarColorByCurrency.DKK}
          />
          <DescriptionList
            title={t("EUR")}
            value={`€ ${formatCentsToDollars(acc.EUR)}`}
            color={BarColorByCurrency.EUR}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export const DescriptionList: React.FC<{
  title: string;
  value: string;
  legendClassName?: string;
  color: string;
}> = ({ title, value, legendClassName = "bg-primary", color }) => (
  <dl className="flex gap-2">
    <span
      style={{ backgroundColor: color }}
      className={cn("block w-3 h-3 rounded-full", legendClassName)}
    ></span>
    <div className="">
      <dt className="text-xs leading-none font-medium">{title}</dt>
      <dd className="text-sm leading-none mt-1">{value}</dd>
    </div>
  </dl>
);
