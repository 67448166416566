import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { ChargeSession, Company, List } from "@elton/types";

import { SessionsPage } from "@/pages/sessions-page/sessions-page";

import { defaultListDateRangeSchema } from "@/api/params/default-list-date-range-params.ts";
import { getChargeSessions } from "@/api/requests/chargings/get-charge-sessions";
import { getCompany } from "@/api/requests/companies/get-company.ts";

export type SessionsParams = z.infer<typeof sessionsSchema>;
const sessionsSchema = defaultListDateRangeSchema.extend({
  sort: z.string().optional().default("-timestamp"),
  includeChildren: z.boolean().optional().default(false),
});

export const Route = createFileRoute("/_authenticated/companies/$companyId/_dashboard/sessions")({
  component: SessionsPage,
  validateSearch: sessionsSchema,
  loaderDeps: (opts): SessionsParams => sessionsSchema.parse(opts.search),
  loader: async ({
    params,
    deps,
  }): Promise<{
    sessions: List<ChargeSession>;
    company: Company;
  }> => {
    return {
      company: await getCompany(params.companyId),
      sessions: await getChargeSessions(params.companyId, deps),
    };
  },
});
