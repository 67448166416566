import { CreateDepartment, CreateEmployee, createEmployeeSchema } from "@elton/types";
import { SheetClose, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "@elton/ui/core";

import { LoadingButton } from "@/components/buttons/loading-button";
import { InputField } from "@/components/fields/input-field";
import { SelectField } from "@/components/fields/select-field";

import { useEmployeeForm } from "./use-employee-form";

import { T, useT } from "@transifex/react";

export type UserFormAction = "create" | "update";

interface IProps {
  departments: CreateDepartment[];
  companyId: string;
  data: CreateEmployee;
  onUpdateUser: (user: CreateEmployee) => void;
  onCreateUser: (user: Omit<CreateEmployee, "id">) => void;
  loading?: boolean;
}

export const EmployeeForm: React.FC<IProps> = ({
  data,
  departments,
  companyId,
  onUpdateUser,
  onCreateUser,
  loading,
}) => {
  const t = useT();
  const form = useEmployeeForm({
    data,
    onSubmitCreate: onCreateUser,
    onSubmitUpdate: onUpdateUser,
  });

  // Reference Field is only available for two companies. This field can be hidden for all other companies.
  const showReferenceField =
    companyId === "0fbbef7d-58da-4879-b12f-d1cffebe9f6f" ||
    companyId === "f3d1d90d-44fe-4759-aa75-31a4dcdae3f3";

  const handleFormSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    void form.handleSubmit();
  };

  return (
    <form.Subscribe
      selector={(state) => (state.values.id === "" ? "create" : "update")}
      children={(action) => (
        <>
          <SheetHeader>
            <SheetTitle>
              {action === "create" ? t("Create employee") : t("Update employee")}
            </SheetTitle>
            <SheetDescription>
              <T _str="Invite a new employee - Click button when you're done." />
            </SheetDescription>
          </SheetHeader>
          <form className="space-y-4 text-primary">
            <form.Field
              name="name"
              validators={{
                onChange: createEmployeeSchema.shape.name,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  label={t("Name")}
                  placeholder="John Smith"
                  required={true}
                />
              )}
            />
            <form.Field
              name="phoneNumber"
              validators={{
                onChange: createEmployeeSchema.shape.phoneNumber,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  label={t("Phone")}
                  placeholder="+4700000000"
                  required={true}
                  disabled={action === "update"}
                />
              )}
            />
            <form.Field
              name="registrationNumber"
              validators={{
                onChange: createEmployeeSchema.shape.registrationNumber,
              }}
              children={(field) => (
                <InputField field={field} label={t("Default Reg. Number")} placeholder="EV123456" />
              )}
            />
            <form.Field
              name="defaultNote"
              validators={{
                onChange: createEmployeeSchema.shape.defaultNote,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  label={t("Default Note")}
                  placeholder={t("A default note")}
                />
              )}
            />
            {showReferenceField && (
              <form.Field
                name="reference"
                validators={{
                  onChange: createEmployeeSchema.shape.reference,
                }}
                children={(field) => (
                  <InputField
                    field={field}
                    label={t("Reference")}
                    placeholder={t("Internal reference")}
                  />
                )}
              />
            )}
            <form.Field
              name="departmentId"
              validators={{
                onChange: createEmployeeSchema.shape.departmentId,
              }}
              children={(field) => (
                <SelectField
                  data={departments}
                  keyExtractor={{ key: "id", value: "name" }}
                  field={field}
                  label={t("Department")}
                  placeholder={t("Select a department")}
                  required={true}
                />
              )}
            />
            <SheetFooter>
              <SheetClose asChild>
                <LoadingButton
                  loading={loading}
                  disabled={!!form.state.errors.length}
                  onClick={handleFormSubmit}
                  type="submit"
                  id="create-employee"
                >
                  {action === "create" ? t("Create") : t("Update")}
                </LoadingButton>
              </SheetClose>
            </SheetFooter>
          </form>
        </>
      )}
    />
  );
};
