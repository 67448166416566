import { createFileRoute } from "@tanstack/react-router";

import { Company, PaymentPlan } from "@elton/types";

import { CompanyOverviewPage } from "@/pages/company-overview-page/company-overview-page";

import { getCompany } from "@/api/requests/companies/get-company";
import { getDepartments } from "@/api/requests/departments/get-departments";
import { getEmployees } from "@/api/requests/employees/get-employees";
import { getCompanyPaymentPlanOverride } from "@/api/requests/payment-plan/get-payment-plan-override";

export const Route = createFileRoute("/_authenticated/companies/$companyId/_dashboard/company")({
  component: CompanyOverviewPage,
  loader: async ({
    params,
  }): Promise<{
    company: Company;
    companyPlan: PaymentPlan | null;
    companyId: string;
    employeesTotal: number;
    departmentsTotal: number;
  }> => {
    const employees = await getEmployees(params.companyId);
    const departments = await getDepartments(params.companyId, {
      offset: 0,
      limit: 1,
      sort: "",
      filter: "",
    });

    return {
      company: await getCompany(params.companyId),
      companyPlan: await getCompanyPaymentPlanOverride(params.companyId),
      companyId: params.companyId,
      employeesTotal: employees.total,
      departmentsTotal: departments.total,
    };
  },
});
