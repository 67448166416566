import { List, ListDepartment } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";
import { DefaultListParams } from "@/api/params/default-list-params";

export const getDepartments = async (companyId: string, params?: DefaultListParams) => {
  const res = await getServicesClient().get<List<ListDepartment>>(
    `/companies/${companyId}/departments`,
    { params },
  );

  return res.data;
};
