import { format, isSameMonth } from "date-fns";

import {
  Button,
  ButtonProps,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { ChevronLeft, ChevronRight } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { useMonthSelector } from "./use-month-selector";

interface IProps {
  firstDate?: Date;
  lastDate?: Date;
  date?: Date;
  onSelect: (date: Date) => void;
}

export const SelectMonthDropdown: React.FC<IProps> = ({
  firstDate = new Date(2023, 11, 1),
  lastDate = new Date(2024, 11, 1),
  date = new Date(),
  onSelect,
}) => {
  const monthSelector = useMonthSelector({
    firstDate,
    lastDate,
    currentDate: date,
    onSelect,
  });

  return (
    <DropdownMenu>
      <div className="flex border rounded-lg">
        <Button
          {...defaultProps}
          disabled={!monthSelector.hasPreviousMonth}
          onClick={monthSelector.selectPrevMonth}
        >
          <ChevronLeft className="w-4 h-4" />
        </Button>
        <DropdownMenuTrigger asChild>
          <Button {...defaultProps} className="select-none w-[120px]">
            {format(monthSelector.selectedDate, "MMMM yyyy")}
          </Button>
        </DropdownMenuTrigger>
        <Button
          {...defaultProps}
          disabled={!monthSelector.hasNextMonth}
          onClick={monthSelector.selectNextMonth}
        >
          <ChevronRight className="w-4 h-4" />
        </Button>
      </div>
      <DropdownMenuContent align="center" className="w-[450px]">
        <div>
          <div className="flex justify-center items-center gap-4">
            <Button
              {...defaultProps}
              onClick={monthSelector.selectPrevYear}
              disabled={!monthSelector.hasPreviousYear}
              className="disabled:opacity-50"
            >
              <ChevronLeft className="w-4 h-4" />
            </Button>
            <span className="text-sm font-medium h-full">{monthSelector.activeYear}</span>
            <Button
              {...defaultProps}
              onClick={monthSelector.selectNextYear}
              disabled={!monthSelector.hasNextYear}
              className="disabled:opacity-50"
            >
              <ChevronRight className="w-4 h-4" />
            </Button>
          </div>
        </div>
        <ul className="grid grid-cols-12">
          {monthSelector.activePage.map((date, i) => (
            <li key={i} className="col-span-4">
              <Button
                {...defaultProps}
                disabled={date > new Date() || date < firstDate}
                onClick={() => monthSelector.selectMonth(date)}
                className={cn(
                  "w-full",
                  isSameMonth(date, monthSelector.selectedDate) &&
                    "bg-button text-white hover:bg-button-active hover:text-white hover:opacity-95",
                )}
              >
                {format(date, "MMMM")}
              </Button>
            </li>
          ))}
        </ul>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const defaultProps: ButtonProps = {
  variant: "ghost",
  size: "sm",
};
