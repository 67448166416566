import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { Account, List } from "@elton/types";

import { AccountsPage } from "@/pages/accounts-page/accounts-page";

import { defaultListParamsSchema } from "@/api/params/default-list-params";
import { getAccounts } from "@/api/requests/accounts/get-accounts";

const SearchParamsSchema = defaultListParamsSchema.extend({
  open: z.string().optional(),
});

export type AccountsSearchParams = z.infer<typeof SearchParamsSchema>;

export const Route = createFileRoute("/_authenticated/companies/$companyId/_dashboard/accounts")({
  component: AccountsPage,
  validateSearch: SearchParamsSchema,
  loaderDeps: ({ search: { offset, limit, filter, sort, open } }) => ({
    offset,
    limit,
    filter,
    sort,
    open,
  }),
  loader: async ({
    params,
    deps,
  }): Promise<{
    accounts: List<Account>;
    companyId: string;
  }> => {
    return {
      accounts: await getAccounts(params.companyId, deps),
      companyId: params.companyId,
    };
  },
});
