import * as Recharts from "recharts";

import { Currency } from "@elton/types";
import { formatCentsToDollars, localFormatDate } from "@elton/utils";

import { CustomTooltip } from "../components/custom-tooltip/custom-tooltip";

import { BarColorByCurrency } from "./bar-chart-colors";

interface IProps {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  xAxisKey: string;
  yAxisKeys: string[];
}

export const BarChart: React.FC<IProps> = ({ className, data, xAxisKey, yAxisKeys }) => {
  return (
    <Recharts.ResponsiveContainer width="100%" height="100%" className={className}>
      <Recharts.BarChart data={data}>
        <Recharts.XAxis
          dataKey={xAxisKey}
          tickMargin={15}
          tickLine={true}
          interval={1}
          tickFormatter={(val) => localFormatDate(val, "MONTH_DAY_COMPACT")}
          tick={tickProps}
        />
        <Recharts.YAxis
          tickLine={false}
          tick={tickProps}
          tickCount={10}
          tickFormatter={(val) => `${formatCentsToDollars(val as number)}`}
          domain={["dataMin", "dataMax"]}
        />
        {yAxisKeys &&
          yAxisKeys.map((key, i) => {
            return (
              <Recharts.Bar
                key={`bar-${i}`}
                dataKey={key}
                radius={[5, 5, 0, 0]}
                fill={BarColorByCurrency[key as Currency]}
              ></Recharts.Bar>
            );
          })}
        <Recharts.Tooltip cursor={cursorProps} content={<CustomTooltip attributes={yAxisKeys} />} />
      </Recharts.BarChart>
    </Recharts.ResponsiveContainer>
  );
};

const cursorProps: React.SVGProps<SVGElement> = {
  fill: "#808080",
  opacity: 0.2,
  strokeWidth: 2,
};

const tickProps: React.SVGProps<SVGTextElement> = {
  fontSize: 12,
};
