import { Enhet, EnhetSchema } from "@elton/types";

export const fetchEnhet = async (orgnr: string): Promise<Enhet> => {
  const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`);
  if (!response.ok) {
    throw new Error("Failed to fetch enhet");
  }
  const data = await response.json();
  return EnhetSchema.parse(data);
};
