import { useState } from "react";
import { endOfMonth, format, startOfMonth } from "date-fns";

import { CreateMasterExport, ExportFormat } from "@elton/types";
import { Calendar, Popover, PopoverContent, PopoverTrigger } from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";

import { ExportDropdown } from "@/components/dropdowns/export-dropdown/export-dropdown";
import { DateField } from "@/components/fields/date-field";

import { masterExportInternalSchema } from "./master-export-types";
import { useMasterExportInternalForm } from "./use-master-export-form";

import { useT } from "@transifex/react";

interface IProps {
  loading?: boolean;
  onCreateExport: (exp: CreateMasterExport) => void;
  onChange: (value: unknown) => void;
}

export const MasterExportForm: React.FC<IProps> = ({ onCreateExport }) => {
  const t = useT();

  const [currentDate, setCurrentDate] = useState<Date | null>(null);

  const form = useMasterExportInternalForm({
    data: { date: currentDate ?? undefined },
    onSubmit: () => {},
  });

  const handleFormSubmission = (format: "csv" | "xlsx") => {
    const date = form.getFieldValue("date");

    if (!date) {
      console.warn("No date selected");
      return;
    }

    onCreateExport({
      monthNumber: date.getMonth() + 1,
      year: date.getFullYear(),
      format: format === "csv" ? ExportFormat.CSV : ExportFormat.XLSX,
    });
  };

  const startOfMonthDate = currentDate ? startOfMonth(currentDate) : undefined;
  const endOfMonthDate = currentDate ? endOfMonth(currentDate) : undefined;

  return (
    <form.Subscribe
      children={(state) => (
        <>
          <Popover>
            <form className={cn("flex items-end gap-2 text-primary")} tabIndex={-1}>
              <PopoverTrigger className="flex flex-row gap-2" tabIndex={-1}>
                <form.Field
                  name="date"
                  validators={{
                    onChange: masterExportInternalSchema.shape.date,
                  }}
                  children={(field) => (
                    <>
                      <DateField
                        className="w-full xl:w-[200px]"
                        tabIndex={0}
                        field={field}
                        label=""
                        placeholder={t("Select month")}
                        required={true}
                        onClickReset={(e: any) => {
                          e.preventDefault();
                          setCurrentDate(null);
                          //handleValueChange(undefined);
                        }}
                        renderValue={(value) => (value ? format(value, "MMMM yyyy") : "")}
                      />
                    </>
                  )}
                />
              </PopoverTrigger>
              <ExportDropdown onExport={handleFormSubmission} disabled={!state.values.date} />
            </form>
            <PopoverContent className="w-auto p-0 flex" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={currentDate ?? undefined}
                selected={{
                  from: startOfMonthDate,
                  to: endOfMonthDate,
                }}
                onDayClick={(day) => setCurrentDate(day)}
                fromYear={2020}
                toDate={endOfMonth(new Date())}
                numberOfMonths={2}
              />
            </PopoverContent>
          </Popover>
        </>
      )}
    />
  );
};
