import { getServicesClient } from "@/api/clients/api-client";
import { DefaultListParams } from "@/api/params/default-list-params";

export const getEmployees = async (companyId: string, params?: DefaultListParams) => {
  const res = await getServicesClient().get(`/companies/${companyId}/users`, {
    params,
  });

  return res.data;
};
