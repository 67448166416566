import { ChargeSession, List } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";
import { SessionsParams } from "@/routes/_authenticated/companies/$companyId/_dashboard/sessions.tsx";

export const getChargeSessions = async (companyId: string, params: SessionsParams) => {
  const { includeChildren, ...query } = params;
  const res = await getServicesClient().post<List<ChargeSession>>(
    `/companies/${companyId}/sessions`,
    { ...query },
    { params: { includeChildren } },
  );

  return res.data;
};
