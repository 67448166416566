import { createContext, useEffect, useState } from "react";

export type Theme = "light" | "dark" | "system";

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  theme: "system",
  setTheme: () => null,
};

/**
 * Context for the theme provider.
 */
export const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

/**
 * Provides a theme context for the application.
 *
 * @param children - The child components to be wrapped by the theme provider.
 * @param defaultTheme - The default theme to be used if no theme is set.
 * @param storageKey - The key used to store the theme in local storage.
 *
 * @returns The theme provider component.
 */
export function ThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "elton-ui-theme",
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(
    (window.localStorage.getItem(storageKey) as Theme) || defaultTheme,
  );

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove("light", "dark");

    if (theme === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const value = {
    theme,
    setTheme: (theme: Theme) => {
      localStorage.setItem(storageKey, theme);
      setTheme(theme);
    },
  };

  return <ThemeProviderContext.Provider value={value}>{children}</ThemeProviderContext.Provider>;
}
