export function splitNameAtLastSpace(fullName: string): [string, string] {
  const lastSpaceIndex = fullName.lastIndexOf(" ");

  if (lastSpaceIndex === -1) {
    // No space found, return the whole name as last name
    return ["", fullName];
  }

  const firstName = fullName.substring(0, lastSpaceIndex);
  const lastName = fullName.substring(lastSpaceIndex + 1);

  return [firstName, lastName];
}
