import {
  BasePaymentPlan,
  Country,
  CreateCompany,
  CreateCompanyAndAdmin,
  createCompanyAndAdminSchema,
} from "@elton/types";
import { SheetClose, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "@elton/ui/core";
import { LoadingButton } from "@/components/buttons/loading-button";
import { InputField } from "@/components/fields/input-field";
import { SelectField } from "@/components/fields/select-field";
import { TFormAction } from "../../form-types";
import { useCreateCompanyForm } from "./use-create-company-form";
import { T, useT } from "@transifex/react";
import { fetchEnhet } from "@/api/requests/brreg/get-organization-info.ts";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { splitNameAtLastSpace } from "@elton/utils";

interface IProps {
  data: any;
  paymentPlans: BasePaymentPlan[];
  onCreate: (data: CreateCompanyAndAdmin) => void;
  onUpdate: (data: CreateCompany) => void;
  loading?: boolean;
}

export const CreateCompanyForm: React.FC<IProps> = ({
  data,
  paymentPlans,
  onCreate,
  onUpdate,
  loading,
}) => {
  const t = useT();
  const [orgnr, setOrgnr] = useState<string>("");
  const [checked, setChecked] = useState(false);

  const handleCheckbox = (e: any) => {
    const [firstName, lastName] = splitNameAtLastSpace(form.getFieldValue("contactName"));
    form.setFieldValue("adminFirstName", firstName ?? "");
    form.setFieldValue("adminLastName", lastName ?? "");
    form.setFieldValue("adminEmail", form.getFieldValue("contactEmail"));
    setChecked(e.target.checked);
  };
  const useEnhet = (orgnr: string) => {
    return useQuery({
      queryKey: ["enhet", orgnr],
      queryFn: () => fetchEnhet(orgnr),
    });
  };

  const enhet = useEnhet(orgnr);

  useEffect(() => {
    if (enhet.isSuccess && enhet) {
      form.setFieldValue("name", enhet.data.navn);

      const address = enhet.data.postadresse ?? enhet.data.forretningsadresse;

      if (address != undefined) {
        form.setFieldValue("address", address.adresse.join(", "));
        form.setFieldValue("postalCode", address.postnummer);
        form.setFieldValue("city", address.poststed);
        form.setFieldValue("country", address.land as Country);
      }
    }
  });

  const form = useCreateCompanyForm({
    data,
    onSubmit: (company: any, action: TFormAction) => {
      if (action === "create") {
        onCreate(company as CreateCompanyAndAdmin);
      } else {
        onUpdate(company as CreateCompany);
      }
    },
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    void form.handleSubmit();
  };

  return (
    <form.Subscribe
      selector={() => {
        return ["create"];
      }}
      children={([action]) => (
        <>
          <SheetHeader>
            <SheetTitle>
              {action === "create" ? t("Create company") : t("Update company")}
            </SheetTitle>
            <SheetDescription>
              <T _str="Create a new company - Click the button when you're done." />
            </SheetDescription>
          </SheetHeader>
          <form autoComplete={"off"} className="grid grid-cols-12 gap-2 text-primary">
            <form.Field
              name="organizationNumber"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.organizationNumber,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Organisation Number")}
                  placeholder=""
                  required={true}
                  onInput={(e) =>
                    e.currentTarget.value.length == 9 ? setOrgnr(e.currentTarget.value) : null
                  }
                />
              )}
            />
            <form.Field
              name="name"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.name,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Company Name")}
                  placeholder={""}
                  required={true}
                />
              )}
            />
            <div className={"p-2 col-span-full"}></div>
            <form.Field
              name="address"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.address,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Street Address")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <form.Field
              name="postalCode"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.postalCode,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Postal Code")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <form.Field
              name="city"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.city,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("City")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <form.Field
              name="country"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.country,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Country")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <h3 className={"col-span-full font-medium mt-6"}>{t("Contact Person")}</h3>
            <form.Field
              name="contactName"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.contactName,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Name")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <form.Field
              name="contactPhone"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.contactPhone,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Phone")}
                  placeholder="+47xxxxxxxx"
                  required={true}
                />
              )}
            />
            <form.Field
              name="contactEmail"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.contactEmail,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Email")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <h3 className={"col-span-full font-medium mt-6"}>{t("Administrator")}</h3>
            <div className="checkbox-container col-span-full">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={handleCheckbox}
                  id="sameAsContact"
                  name="sameAsContact"
                />
                <span className="checkbox-text text-sm ml-2">{t("Same as contact person")}</span>
              </label>
            </div>
            <form.Field
              name="adminFirstName"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.adminFirstName,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("First Name")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <form.Field
              name="adminLastName"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.adminLastName,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full md:col-span-6"
                  label={t("Last Name")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <form.Field
              name="adminEmail"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.adminEmail,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Email")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <div className={"p-2 col-span-full"}></div>
            <form.Field
              name="invoiceEmail"
              validators={{
                onBlur: createCompanyAndAdminSchema.shape.invoiceEmail,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  className="col-span-full"
                  label={t("Invoice email")}
                  placeholder=""
                  required={true}
                />
              )}
            />
            <form.Field
              name="paymentPlanId"
              validators={{
                onChange: createCompanyAndAdminSchema.shape.paymentPlanId,
              }}
              children={(field) => (
                <SelectField
                  data={paymentPlans}
                  keyExtractor={{ key: "id", value: "name" }}
                  field={field}
                  label={t("Payment plan")}
                  placeholder={t("Select a payment plan")}
                  className="col-span-full"
                />
              )}
            />
            <form.Field
              name="discountPercentage"
              children={(field) => (
                <InputField
                  field={field}
                  type="number"
                  className="col-span-full"
                  label={t("Discount percentage")}
                  placeholder="0%"
                  onChange={(e) => field.handleChange(Number(e.target.value))}
                />
              )}
            />
            <SheetFooter className="col-span-full">
              <SheetClose asChild>
                <LoadingButton
                  loading={loading}
                  onClick={handleFormSubmit}
                  type="submit"
                  id="create-company"
                >
                  {action === "create" ? t("Create") : t("Update")}
                </LoadingButton>
              </SheetClose>
            </SheetFooter>
          </form>
        </>
      )}
    />
  );
};
