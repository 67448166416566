import { useCallback, useMemo, useState } from "react";
import { addMonths, endOfYear, startOfMonth, startOfYear, subMonths } from "date-fns";

import { getMonthsInYears } from "./select-month-utils/get-months-paginated-by-year";

interface IProps {
  firstDate: Date;
  lastDate: Date;
  currentDate: Date;
  onSelect: (date: Date) => void;
}

export const useMonthSelector = ({ firstDate, lastDate, currentDate, onSelect }: IProps) => {
  const pages = useMemo(
    () =>
      getMonthsInYears({
        start: startOfYear(firstDate),
        end: endOfYear(lastDate),
      }),
    [firstDate, lastDate],
  );

  const [selectedDate, setSelectedDate] = useState<Date>(startOfMonth(currentDate));
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());

  const handleSelectMonth = useCallback(
    (date: Date) => {
      setSelectedDate(date);
      onSelect(date);
    },
    [onSelect],
  );

  const handleSelectPrevMonth = useCallback(() => {
    handleSelectMonth(subMonths(selectedDate, 1));
  }, [handleSelectMonth, selectedDate]);

  const handleSelectNextMonth = useCallback(() => {
    handleSelectMonth(addMonths(selectedDate, 1));
  }, [handleSelectMonth, selectedDate]);

  const handleSelectPrevYear = useCallback(() => {
    setSelectedYear((prev) => prev - 1);
  }, []);

  const handleSelectNextYear = useCallback(() => {
    setSelectedYear((prev) => prev + 1);
  }, []);

  return {
    selectedDate,
    activeYear: selectedYear,
    activePage: pages[selectedYear] || [],
    hasPreviousYear: pages[selectedYear - 1],
    hasNextYear: pages[selectedYear + 1],
    hasPreviousMonth: selectedDate > startOfMonth(firstDate),
    hasNextMonth: selectedDate < startOfMonth(new Date()),
    selectMonth: handleSelectMonth,
    selectPrevMonth: handleSelectPrevMonth,
    selectNextMonth: handleSelectNextMonth,
    selectPrevYear: handleSelectPrevYear,
    selectNextYear: handleSelectNextYear,
  };
};
