import { Link } from "@tanstack/react-router";

export interface IFooterNavigationItem {
  title: string;
  href: string;
  external?: boolean;
}

interface IProps {
  title: string;
  items: IFooterNavigationItem[];
  className?: string;
}

export const FooterNavigationMenu: React.FC<IProps> = ({ title, items, className }) => {
  return (
    <nav className={className}>
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li
            key={index}
            className="text-sm text-muted-foreground hover:opacity-75 transition-opacity"
          >
            <Link to={item.href} params={{}}>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
