import { StatsEntryType } from "node_modules/@elton/types/src/types/metrics/stats";

import { CompanyMetrics } from "@elton/types";
import { NumberFormatter } from "@elton/utils";

import { PlugZap, Users, Zap } from "@ui/components/icons";

import { MetricsCard } from "@/components/cards/metrics-card/metrics-card";

import { useT } from "@transifex/react";

interface IProps {
  metrics: CompanyMetrics;
}

export const StatsWidget: React.FC<IProps> = ({ metrics }) => {
  const t = useT();

  return (
    <div className={ClassNames.Container}>
      <MetricsCard
        value={`${NumberFormatter.round(metrics.chargedKwh)} kWh`}
        title={t("Charged for")}
        description={t(
          "The total amount of energy (kilowatt per hour) charged for the current month",
        )}
        progress={0.0}
        icon={StatsIcons.charged_for}
        className={ClassNames.Card}
      />
      <MetricsCard
        value={`${metrics.sessions}`}
        description={t("The total number of charge sessions for the current month")}
        title={t("Charging sessions")}
        progress={0.0}
        icon={StatsIcons.sessions}
        className={ClassNames.Card}
      />
      <MetricsCard
        value={`${metrics.chargingUsers}`}
        title={t("Charging employees")}
        description={t(
          "The total number of your employees that has charged at least once for the current month",
        )}
        progress={0.0}
        icon={StatsIcons.users}
        className={ClassNames.Card}
      />
    </div>
  );
};

const ClassNames: Record<string, string> = {
  Container: "grid grid-cols-12 gap-4",
  Card: "col-span-full md:col-span-6 lg:col-span-4",
};

const StatsIcons: Record<StatsEntryType, React.ReactNode> = {
  charged_for: <Zap className="w-4 h-auto" />,
  sessions: <PlugZap className="w-4 h-auto" />,
  users: <Users className="w-4 h-auto" />,
};
