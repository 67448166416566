import { endOfDay, lastDayOfMonth, startOfDay, startOfMonth } from "date-fns";
import { DateRange } from "./date-types";

/**
 * A utility function to get the monthly date range for a given input date.
 *
 * @param date - The date to get the range for.
 * @returns The date range for the given date.
 */
export const getMonthlyDateRange = (date: Date): DateRange => {
  return {
    start: startOfMonth(date),
    end: lastDayOfMonth(date),
  };
};

/**
 * A utility function that returns the epoch timestamps of the applied date range.
 */
type NormalizedDateRange = { start?: string; end?: string };
export const getEpochOfDateRange = (dateRange: DateRange): NormalizedDateRange => {
  const normalized: NormalizedDateRange = {
    start: undefined,
    end: undefined,
  };

  if (dateRange.start) {
    normalized.start = startOfDay(dateRange.start).toISOString();
  }

  if (dateRange.end) {
    normalized.end = endOfDay(dateRange.end).toISOString();
  }

  return normalized;
};
