interface IProps {
  className?: string;
}

export const EltonLogoSymbolIcon: React.FC<IProps> = ({ className }) => {
  return (
    <svg
      width="52"
      height="51"
      viewBox="0 0 52 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="26.27" cy="25.5015" r="25.5015" fill="white" />
      <circle cx="26.2698" cy="25.5017" r="22.001" fill="#00AB98" />
      <path
        d="M13.4394 26.7631L26.7637 20.8062C27.9662 20.2686 29.2703 21.2686 29.2703 22.7282L29.2703 34.1349C29.2703 35.5686 28.0086 36.5671 26.8142 36.0785L13.4899 30.6287C11.9048 29.9804 11.8719 27.4639 13.4394 26.7631Z"
        fill="white"
      />
      <path
        d="M39.1006 19.0612L25.7763 13.1043C24.5739 12.5667 23.2698 13.5667 23.2698 15.0263L23.2698 26.433C23.2698 27.8667 24.5314 28.8651 25.7258 28.3766L39.0501 22.9268C40.6352 22.2784 40.6681 19.762 39.1006 19.0612Z"
        fill="white"
      />
      <path
        d="M23.2698 47.2999V33.6306C23.2698 31.9028 24.613 30.5022 26.2699 30.5022C27.9269 30.5022 29.2701 31.9028 29.2701 33.6306V47.2998C28.2891 47.4336 27.2875 47.5027 26.2697 47.5027C25.2521 47.5027 24.2507 47.4336 23.2698 47.2999Z"
        fill="white"
      />
      <path opacity="0.3" d="M23.2698 34.6354V37.0914H29.2701L23.2698 34.6354Z" fill="#00AB98" />
    </svg>
  );
};
