import { format } from "date-fns";
import { DateRange } from "../date/date-types";

export const createFilename = (prefix: string, filetype: string, dateRange: DateRange) => {
  const startSegment = dateRange.start ? format(dateRange.start, "LLL dd") : "";
  const endSegment = dateRange.end ? format(dateRange.end, "LLL dd yyyy") : "";

  return `${prefix} ${startSegment} - ${endSegment}.${filetype}`;
};

export const saveFile = (filename: string, filedata: any) => {
  const href = URL.createObjectURL(filedata);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
