import { CreateExport } from "@elton/types";
import { createFilename, getEpochOfDateRange, saveFile } from "@elton/utils";

import { getServicesClient } from "@/api/clients/api-client";

export type MutationFnParams = CreateExport & { filter?: string } & {
  format: "csv" | "xlsx";
  includeChildren: boolean;
};

export const createSessionsExport = async (companyId: string, body: MutationFnParams) => {
  const normalizedDateRange = getEpochOfDateRange({
    start: body.dateFrom,
    end: body.dateTo,
  });

  const res = await getServicesClient().post(
    `/companies/${companyId}/sessions/${body.format}${body.includeChildren ? "?includeChildren=true" : ""}`,
    {
      dateFrom: normalizedDateRange.start,
      dateTo: normalizedDateRange.end,
      filter: body.filter,
    },
    {
      responseType: "blob",
    },
  );

  const filename = createFilename("Sessions", body.format, {
    start: body.dateFrom,
    end: body.dateTo,
  });

  saveFile(filename, res.data);

  return res.data;
};
