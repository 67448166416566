import { ISidebarNavigationItem } from "@/types/navigation/sidebar-navigation";

/**
 * Finds the active sidebar navigation item based on the current pathname.
 *
 * @param pathname - The current pathname.
 * @param items - The array of sidebar navigation items to search through.
 * @returns The active sidebar navigation item if found, otherwise null.
 */
export const findActiveSidebarNavigationItem = (
  pathname: string,
  items: ISidebarNavigationItem[],
): ISidebarNavigationItem | null => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    if (item) {
      if (item.href === pathname) {
        return item;
      }

      if (item.subItems) {
        for (let j = 0; j < item.subItems.length; j++) {
          const subItem = item.subItems[j];
          if (subItem) {
            if (subItem.href === pathname) {
              return item;
            }
          }
        }
      }
    }
  }

  return null;
};
