import { useEffect, useState } from "react";
import { SortingState } from "@tanstack/react-table";

import { parseSortParam } from "../dynamic-table-utils";

export const useTableSorting = (sortStr: string) => {
  const parsedSort = parseSortParam(sortStr);
  const initialField = parsedSort[0] ?? "id";
  const initialOrder = parsedSort[1] ?? "ASC";

  const [sortingState, setSortingState] = useState<SortingState>([
    { id: initialField, desc: initialOrder === "DESC" },
  ]);

  useEffect(() => {
    setSortingState([{ id: initialField, desc: initialOrder === "DESC" }]);
  }, [initialField, initialOrder]);

  return {
    state: sortingState,
    onSortingChange: setSortingState,
    // 🔽 API sorting parameters
    order:
      sortingState.length && sortingState[0]
        ? sortingState[0].desc
          ? "DESC"
          : "ASC"
        : initialOrder,
    field: sortingState.length && sortingState[0] ? sortingState[0].id : initialField,
  };
};
