import { Button, Card, CardContent, CardDescription, CardHeader, CardTitle } from "@elton/ui/core";
import { Edit2 } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { T } from "@transifex/react";

interface IProps {
  title: string;
  description: string;
  data: { key: string; value: string }[];
  className?: string;
  onClickEdit: () => void;
  editable: boolean;
}

export const DetailsCard: React.FC<IProps> = ({
  data,
  description,
  title,
  className,
  onClickEdit,
  editable,
}) => {
  return (
    <Card className={className}>
      <CardHeader>
        <div className="flex justify-between mb-2">
          <div>
            <CardTitle className="text-md font-medium">{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
          </div>
          {editable && (
            <Button size="sm" variant="outline" onClick={onClickEdit}>
              <Edit2 className="w-3 h-3 mr-2" />
              <span className="text-sm font-normal">
                <T _str="Edit" />
              </span>
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent>
        <dl className="rounded-lg overflow-hidden">
          {data.map((item, i) => (
            <div
              className={cn(
                "flex justify-between bg-muted/50 p-3 min-h-11",
                i % 2 !== 0 ? "bg-muted/10" : "bg-muted/50",
              )}
              key={i}
            >
              <dt className="text-sm font-medium whitespace-nowrap">{item.key}</dt>
              <dd className="text-sm">{item.value}</dd>
            </div>
          ))}
        </dl>
      </CardContent>
    </Card>
  );
};
