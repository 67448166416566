import { useContext } from "react";

import { RoleProviderContext } from "./role-context";

export const useRole = () => {
  const context = useContext(RoleProviderContext);

  if (context === undefined) throw new Error("useRole must be used within a RoleProvider");

  return context;
};
