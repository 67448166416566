import { StrictMode, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RegisteredRouter, RouterProvider } from "@tanstack/react-router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import { Toaster } from "@elton/ui/core";

import PostHogAdapter from "./adapters/posthog-adapter.ts";
import SentryAdapter from "./adapters/sentry-adapter";
import TransifexAdapter from "./adapters/transifex-adapter";
import { LocalizationProvider } from "./context/localization-context/localization-context";
import { RoleProvider } from "./context/role-context/role-context";
import { ThemeProvider } from "./context/theme-context/theme-context";

import { KindeProvider, useKindeAuth } from "@kinde-oss/kinde-auth-react";

const queryClient = new QueryClient();

export const App = ({ router }: { router: RegisteredRouter }) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    SentryAdapter.init(router);
    TransifexAdapter.init();
    PostHogAdapter.init();
    setIsInitialized(true);
  }, [router]);

  if (!isInitialized) {
    SentryAdapter.captureException(new Error("EltonPortal not initialized!!"));
    return null;
  }

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <KindeProvider
          clientId={import.meta.env.VITE_KINDE_CLIENT_ID}
          domain={import.meta.env.VITE_KINDE_DOMAIN}
          logoutUri={import.meta.env.VITE_KINDE_LOGOUT_URL}
          redirectUri={import.meta.env.VITE_KINDE_REDIRECT_URL}
          isDangerouslyUseLocalStorage={!import.meta.env.PROD}
        >
          <PostHogProvider client={posthog}>
            <RoleProvider>
              <ThemeProvider>
                <LocalizationProvider>
                  <InnerApp router={router} />
                  <Toaster />
                </LocalizationProvider>
              </ThemeProvider>
            </RoleProvider>
          </PostHogProvider>
        </KindeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
};

export const InnerApp = ({ router }: { router: RegisteredRouter }) => {
  const auth = useKindeAuth();

  return <RouterProvider router={router} context={{ auth }} />;
};
