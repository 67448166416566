import { useCallback, useState } from "react";

type FunctionType = <TKey extends string>(
  value: Record<TKey, boolean>,
) => {
  data: Record<TKey, boolean>;
  select(v: TKey): void;
};

/**
 * Custom hook that provides a selector for managing selected values.
 *
 * @template TValue - The type of the values in the selector.
 * @param {Record<TValue, boolean>} initialState - The initial state of the selector.
 * @returns {Object} - An object containing the selected values and a function to handle selection.
 */
export const useSelector: FunctionType = <TValue extends string>(
  initialState: Record<TValue, boolean>,
) => {
  const [selected, setSelected] = useState<Record<TValue, boolean>>({
    ...initialState,
  });

  const handleSelect = useCallback((v: TValue) => {
    setSelected((prev) => ({
      ...prev,
      [v]: !prev[v],
    }));
  }, []);

  return {
    data: selected,
    select: handleSelect,
  };
};
