import { forwardRef } from "react";

import { Button } from "@elton/ui/core";
import { Pencil, Plus } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

type IconVariant = "create" | "update";
type Ref = HTMLButtonElement;

interface IProps {
  children: React.ReactNode;
  className?: string;
  variant?: IconVariant;
}

const Icons: Record<IconVariant, React.ReactNode> = {
  create: <Plus />,
  update: <Pencil />,
};

export const IconButton = forwardRef<Ref, IProps>(
  ({ children, className, variant, ...rest }, ref) => {
    return (
      <Button {...rest} variant="secondary" className={cn("gap-1", className)} ref={ref}>
        {Icons[variant ?? "create"]}
        <span>{children}</span>
      </Button>
    );
  },
);
