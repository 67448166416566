import { createFileRoute } from "@tanstack/react-router";

import { BasePaymentPlan, Company, List, PaymentPlan } from "@elton/types";

import { CompanyDetailsPage } from "@/pages/company-details-page/company-details-page";

import { getCompanies } from "@/api/requests/companies/get-companies.ts";
import { getCompany } from "@/api/requests/companies/get-company";
import { getBasePaymentPlans } from "@/api/requests/payment-plan/get-base-payment-plans";
import { getCompanyPaymentPlanOverride } from "@/api/requests/payment-plan/get-payment-plan-override";

export const Route = createFileRoute("/_authenticated/companies/$companyId/_dashboard/settings")({
  component: CompanyDetailsPage,
  loader: async ({
    params,
  }): Promise<{
    companyId: string;
    company: Company;
    companyPlan: PaymentPlan | null;
    paymentPlans: List<BasePaymentPlan>;
    hasParentAccess: boolean;
  }> => {
    const company = await getCompany(params.companyId);
    const userCompanies = (await getCompanies()).data;
    const hasAccess = (companyId?: string) => userCompanies.some((comp) => comp.id === companyId);
    return {
      companyId: params.companyId,
      company: company,
      companyPlan: await getCompanyPaymentPlanOverride(params.companyId),
      paymentPlans: await getBasePaymentPlans(),
      hasParentAccess: hasAccess(company.parentCompanyId),
    };
  },
});
