import React from "react";
import { cn } from "@ui/lib/utils";

interface IProps {
  title: string;
  active?: boolean;
  disabled?: boolean;
  className?: string;
}

export const NavigationLinkSub: React.FC<IProps> = ({ title, active, disabled, className }) => {
  return (
    <span
      className={cn(
        "group flex items-center rounded-md px-3 py-2 text-xs text-primary font-medium hover:bg-accent hover:text-accent-foreground",
        active ? "bg-accent" : "transparent",
        disabled && "cursor-not-allowed opacity-50 hover:bg-transparent hover:text-current",
        className,
      )}
    >
      <span>{title}</span>
    </span>
  );
};
