import { useEffect, useState } from "react";

import { Account } from "@elton/types";
import { defaultAccount } from "@elton/types/mocks";
import { AlertDialog, SearchInput, Sheet, SheetContent, useToast } from "@elton/ui/core";

import { AccountForm } from "@/components/forms/account-form/account-form";
import { useAccountColumns } from "@/components/tables/columns/account-columns";
import { DynamicTable } from "@/components/tables/dynamic-table/dynamic-table";

import { useDebouncedSearchNavigation } from "@/hooks/search/use-search-navigation";

import { DefaultDialogContent } from "../_common/default-alert-content";
import { DefaultTopSectionContent } from "../_common/default-top-section-content";

import { useCreateAccountMutation } from "@/api/mutations/accounts/create-account-mutation";
import { useDeleteAccountMutation } from "@/api/mutations/accounts/delete-account-mutation";
import { useUpdateAccountMutation } from "@/api/mutations/accounts/update-account-mutation";
import {
  AccountsSearchParams,
  Route as AccountsRoute,
} from "@/routes/_authenticated/companies/$companyId/_dashboard/accounts";
import { useT } from "@transifex/react";

export const AccountsPage: React.FC = () => {
  const t = useT();
  const { toast } = useToast();

  const loaderData = AccountsRoute.useLoaderData();
  const searchParams = AccountsRoute.useSearch<AccountsSearchParams>();

  const [formOpen, setFormOpen] = useState(typeof searchParams.open !== "undefined");
  const [formData, setFormData] = useState<Account>(defaultAccount);
  const [selected, setSelected] = useState<Account | null>(null);

  const createAccount = useCreateAccountMutation(loaderData.companyId);
  const updateAccount = useUpdateAccountMutation();
  const deleteAccount = useDeleteAccountMutation(loaderData.companyId);

  const { updateSearchFilter, removeOpenParameter } = useDebouncedSearchNavigation(
    AccountsRoute.fullPath,
    searchParams,
  );

  const columns = useAccountColumns({
    onUpdate: (value: Account) => setFormData(value),
    onDelete: (value: Account) => setSelected(value),
    onCopyId: (value: string) => {
      navigator.clipboard.writeText(value);
      toast({
        variant: "info",
        description: t("ID copied to the clipboard."),
      });
    },
  });

  useEffect(() => {
    if (typeof searchParams.open !== "undefined") {
      removeOpenParameter();
    }
  }, [searchParams.open, removeOpenParameter]);

  const handleProceedWithDeletion = () => {
    if (!selected?.id) {
      return;
    }

    deleteAccount.mutate(selected.id);
  };

  const handleShowCreateForm = () => {
    setFormData(defaultAccount);
  };

  return (
    <AlertDialog>
      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        <DefaultTopSectionContent
          title={t("Admins")}
          description={t("Manage your company's admins. Here you can add, edit, delete admins.")}
          buttonTitle={t("Create admin")}
          showButton={true}
          onClick={handleShowCreateForm}
        />
        <div className="relative w-full">
          <DynamicTable
            renderToolbar={() => {
              return (
                <>
                  <SearchInput
                    defaultValue={searchParams.filter}
                    containerClassName="w-full sm:max-w-96"
                    placeholder={t("Search") + ".."}
                    onChange={updateSearchFilter}
                  />
                </>
              );
            }}
            columns={columns}
            data={loaderData.accounts.data}
            total={loaderData.accounts.total}
          />
        </div>
        <SheetContent className="space-y-4">
          <AccountForm
            loading={createAccount.isPending || updateAccount.isPending}
            data={formData}
            onCreate={async (data) => {
              await createAccount.mutateAsync(data);
              setFormOpen(false);
            }}
            onUpdate={async (data) => {
              await updateAccount.mutateAsync(data);
              setFormOpen(false);
            }}
          />
        </SheetContent>
      </Sheet>
      <DefaultDialogContent
        title="Do you want to proceed?"
        description={`This action cannot be undone. This will permanently delete ${selected?.email} from your organization.`}
        onContinue={handleProceedWithDeletion}
      />
    </AlertDialog>
  );
};
