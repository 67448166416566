import { z } from "zod";
import { validatePhoneNumber } from "../../validators/validate-phone-number";
import { CountryEnum } from "../common/country";

export type ChildCompany = z.infer<typeof childCompanySchema>;
export type CreateChildCompany = z.infer<typeof createChildCompanySchema>;
export type UpdateChildCompany = z.infer<typeof updateChildCompanySchema>;

const childCompanySchema = z.object({
  id: z.string(),
  name: z.string(),
  organizationNumber: z.string(),
  address: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  contactName: z.string().optional(),
  contactPhone: z.string().optional(),
  contactEmail: z.string().email().optional(),
});

export const updateChildCompanySchema = childCompanySchema;

export const createChildCompanySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  organizationNumber: z.string().min(1, { message: "Organization number is required" }),
  address: z.string().min(1, { message: "Street address is required" }),
  postalCode: z.string().min(1, { message: "Postal code is required" }),
  city: z.string().min(1, { message: "City is required" }),
  country: CountryEnum,
  contactName: z.string().min(1, { message: "Contact name is required" }),
  contactPhone: z.string().transform(validatePhoneNumber).optional(),
  contactEmail: z.string().email({ message: "Must be a valid email adress" }),
});
