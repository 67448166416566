import { useEffect, useState } from "react";

import { CreateEmployee, Employee } from "@elton/types";
import { defaultCreateUser } from "@elton/types/mocks";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  SearchInput,
  Sheet,
  SheetContent,
  useToast,
} from "@elton/ui/core";
import { AlertDialog } from "@elton/ui/core";

import { EmployeeForm } from "@/components/forms/employee-form/employee-form";
import { useEmployeeColumns } from "@/components/tables/columns/employee-columns";
import { DynamicTable } from "@/components/tables/dynamic-table/dynamic-table";

import { useDebouncedSearchNavigation } from "@/hooks/search/use-search-navigation";

import { DefaultDialogContent } from "../_common/default-alert-content";
import { DefaultTopSectionContent } from "../_common/default-top-section-content";

import { useCreateEmployeeMutation } from "@/api/mutations/employee/create-employee-mutation";
import { useDeleteEmployeeMutation } from "@/api/mutations/employee/delete-employee-mutation";
import { useUpdateEmployeeMutation } from "@/api/mutations/employee/update-employee-mutation";
import {
  EmployeesSearchParams,
  Route as EmployeesRoute,
} from "@/routes/_authenticated/companies/$companyId/_dashboard/employees";
import { T, useT } from "@transifex/react";

export const EmployeesPage: React.FC = () => {
  const t = useT();
  const { toast } = useToast();

  const loaderData = EmployeesRoute.useLoaderData();
  const searchParams = EmployeesRoute.useSearch<EmployeesSearchParams>();

  const { updateSearchFilter, removeOpenParameter } = useDebouncedSearchNavigation(
    EmployeesRoute.fullPath,
    searchParams,
  );

  const [formOpen, setFormOpen] = useState(typeof searchParams.open !== "undefined");
  const [formData, setFormData] = useState<CreateEmployee>(defaultCreateUser);
  const [selected, setSelected] = useState<Employee | null>(null);

  const createEmployee = useCreateEmployeeMutation(loaderData.companyId);
  const updateEmployee = useUpdateEmployeeMutation();
  const deleteEmployee = useDeleteEmployeeMutation();

  const columns = useEmployeeColumns({
    onEditUser: (value: Employee) =>
      setFormData({
        id: value.id,
        name: value.name,
        phoneNumber: value.phoneNumber,
        registrationNumber: value.registrationNumber,
        defaultNote: value.defaultNote,
        departmentId: value.department?.id!,
      }),
    onDeleteUser: (value: Employee) => setSelected(value),
    onCopyUserId: (value: string) => {
      navigator.clipboard.writeText(value);
      toast({
        variant: "info",
        description: t("ID copied to the clipboard."),
      });
    },
  });

  useEffect(() => {
    if (typeof searchParams.open !== "undefined") {
      removeOpenParameter();
    }
  }, [searchParams.open, removeOpenParameter]);

  const handleProceedWithDeletion = () => {
    if (selected) {
      deleteEmployee.mutate(selected.id!);
    }
  };

  const handleShowCreateForm = () => {
    setFormData(defaultCreateUser);
  };

  return (
    <AlertDialog>
      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        {loaderData.hasReachedUserLimit && (
          <Alert variant="warning">
            <AlertIcon variant="warning" />
            <AlertDescription>
              <T _str="You have reached the maximum number of employees allowed for this business plan. To add more employees, please request a plan upgrade." />
            </AlertDescription>
          </Alert>
        )}
        <DefaultTopSectionContent
          title={t("Employees")}
          description={t(
            "Manage your company's employees. Here you can manage employees and assign them to departments.",
          )}
          buttonTitle={t("Create employee")}
          showButton={!loaderData.hasReachedUserLimit}
          onClick={handleShowCreateForm}
        />
        <div className="relative w-full">
          <DynamicTable
            renderToolbar={() => {
              return (
                <>
                  <SearchInput
                    defaultValue={searchParams.filter}
                    containerClassName="w-full sm:max-w-96"
                    placeholder={t("Search") + ".."}
                    onChange={updateSearchFilter}
                  />
                </>
              );
            }}
            columns={columns}
            data={loaderData.employees.data}
            total={loaderData.employees.total}
            visibilityState={{
              id: false,
              reference: false,
            }}
          />
        </div>
        <SheetContent className="space-y-4">
          <EmployeeForm
            loading={createEmployee.isPending || updateEmployee.isPending}
            data={formData}
            departments={loaderData.departments.data}
            companyId={loaderData.companyId}
            onCreateUser={async (data) => {
              await createEmployee.mutateAsync(data);
              setFormOpen(false);
            }}
            onUpdateUser={async (data) => {
              await updateEmployee.mutateAsync(data);
              setFormOpen(false);
            }}
          />
        </SheetContent>
      </Sheet>
      <DefaultDialogContent
        title={t("Do you want to proceed?")}
        description={t(
          "This action cannot be undone. This will permanently delete {name} from your company.",
          {
            name: selected?.name,
          },
        )}
        onContinue={handleProceedWithDeletion}
      />
    </AlertDialog>
  );
};
