import { Outlet } from "@tanstack/react-router";

import { Toaster } from "@elton/ui/core";

import { cn } from "@ui/lib/utils";

import { Footer } from "@/components/layouts/footer/footer";
import { DashboardHeader } from "@/components/layouts/header/dashboard-header";

interface IProps {
  className?: string;
}

export const PageLayout: React.FC<IProps> = ({ className }) => {
  return (
    <>
      <div className={cn("flex flex-col w-full md:w-full bg-background text-primary", className)}>
        <div className="w-full flex-1 p-2 md:p-6">
          <DashboardHeader />
          <Outlet />
        </div>
        <Footer className="px-2 md:px-6" />
      </div>
      <Toaster />
    </>
  );
};
