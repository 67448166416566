import { Separator } from "@elton/ui/core";

import { BusinessPlanWidget } from "./widgets/business-plan-widget";
import { CompanyInfoWidget } from "./widgets/company-info-widget";
import { CompanyDepartmentsWidget } from "./widgets/departments-widget";
import { EmployeesWidget } from "./widgets/employees-widget";

import { Route as CompanyRoute } from "@/routes/_authenticated/companies/$companyId/_dashboard/company";

export const CompanyOverviewPage: React.FC = () => {
  const loaderData = CompanyRoute.useLoaderData();
  const isChildCompany = !!loaderData.company.parentCompanyId;

  return (
    <>
      <CompanyInfoWidget company={loaderData.company} plan={loaderData.companyPlan} />
      <Separator />
      {!isChildCompany && (
        <BusinessPlanWidget company={loaderData.company} plan={loaderData.companyPlan} />
      )}
      <div className="pt-8 flex flex-col lg:flex-row gap-2 md:gap-4 xl:gap-8">
        <CompanyDepartmentsWidget
          total={loaderData.departmentsTotal}
          max={30}
          className="w-full lg:w-1/2"
          companyId={loaderData.companyId}
        />
        <EmployeesWidget
          total={loaderData.employeesTotal}
          max={loaderData.companyPlan?.userLimit || 0}
          className="w-full lg:w-1/2"
          companyId={loaderData.companyId}
        />
      </div>
    </>
  );
};
