import { parsePhoneNumber } from "awesome-phonenumber";
import { z } from "zod";

type IssueType = "InvalidCountryCode" | "InvalidPhoneNumber";

const PhoneParseIssues: Record<IssueType, z.IssueData> = {
  InvalidCountryCode: {
    code: z.ZodIssueCode.custom,
    message: "Invalid country code",
  },
  InvalidPhoneNumber: {
    code: z.ZodIssueCode.custom,
    message: "Invalid phone number",
  },
};

export const validatePhoneNumber = (value: string, ctx: z.RefinementCtx) => {
  const parsed = parsePhoneNumber(value);

  if (WhitelistedPhoneNumbers.includes(value)) {
    return value;
  }

  if (!parsed.valid) {
    ctx.addIssue(
      parsed.countryCode
        ? PhoneParseIssues.InvalidPhoneNumber
        : PhoneParseIssues.InvalidCountryCode,
    );

    return z.NEVER;
  }

  return parsed.number.e164;
};

// TODO: Should be a set/map instead of a list
const WhitelistedPhoneNumbers = [
  "+4700666666", // Elton test user
];
