import { format } from "date-fns";
import { DateRange } from "./date-types";

export type DateFormat =
  | "SHORT_COMPACT"
  | "LONG_COMPACT"
  | "PRETTY_COMPACT"
  | "MONTH_DAY_COMPACT"
  | "YEAR_MONTH_DAY_COMPACT"
  | "MONTH_DAY_PRETTY"
  | "PRETTY_RANGE";

const DATE_FORMATS: Record<DateFormat, string> = {
  SHORT_COMPACT: "dd/MM-yyyy",
  LONG_COMPACT: "dd/MM-yyyy HH:mm",
  PRETTY_COMPACT: "dd. MMM yyyy (HH:mm)",
  MONTH_DAY_COMPACT: "dd/MM",
  YEAR_MONTH_DAY_COMPACT: "dd:MM:yyyy",
  MONTH_DAY_PRETTY: "dd. MMMM",
  PRETTY_RANGE: "LLL dd, y",
};

/**
 * Formats a date into a localized string representation.
 *
 * @param date - The date to format. It can be a string, number, or Date object.
 * @param dateFormat - The format of the date. Defaults to "SHORT_COMPACT".
 * @returns The formatted date as a string.
 */
export const localFormatDate = (
  date: string | number | Date,
  dateFormat: DateFormat = "SHORT_COMPACT",
): string => {
  return format(new Date(date), DATE_FORMATS[dateFormat]);
};

export const localFormatDateRange = (dateRange: DateRange) => {
  if (!dateRange?.start) {
    return null;
  }

  const { start, end } = dateRange;
  const formattedStart = localFormatDate(start, "PRETTY_RANGE");

  return end ? `${formattedStart} - ${localFormatDate(end, "PRETTY_RANGE")}` : formattedStart;
};

export const decodeDateString = (date?: string): string | undefined => {
  return date ? new Date(decodeURIComponent(date)).toISOString() : undefined;
};
