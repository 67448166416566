import React from "react";

import { IProps } from "../container/sidebar-navigation";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@ui/index";
import { cn } from "@ui/lib/utils";

export const NavigationContentLarge: React.FC<IProps<any>> = ({
  className,
  items,
  renderItem,
  renderSubItem,
}) => {
  return (
    <nav className={cn("flex flex-col gap-2", className)}>
      {items?.map((item: any, index: number) => {
        return (
          <Collapsible key={index} asChild className="group">
            <div className="overflow-visible">
              <CollapsibleTrigger asChild>{renderItem(item, index)}</CollapsibleTrigger>
              {item.subItems && (
                <CollapsibleContent asChild>
                  <nav className="flex flex-col ml-6 mt-2 gap-1">
                    {item.subItems.map((subItem: any, index: number) =>
                      renderSubItem(subItem, index),
                    )}
                  </nav>
                </CollapsibleContent>
              )}
            </div>
          </Collapsible>
        );
      })}
    </nav>
  );
};
