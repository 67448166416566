import { useNavigate } from "@tanstack/react-router";
import { format } from "date-fns";

import { SelectMonthDropdown } from "@/components/dropdowns/select-month-dropdown/select-month-dropdown";

import { Route as DashboardRoute } from "@/routes/_authenticated/companies/$companyId/_dashboard/dashboard";
import { SpendingsWidget } from "@/widgets/spendings-widget/spendings-widget";
import { StatsWidget } from "@/widgets/stats-widget/stats-widget";

export const DashboardPage: React.FC = () => {
  const loaderData = DashboardRoute.useLoaderData();
  const search = DashboardRoute.useSearch();

  const navigate = useNavigate({ from: DashboardRoute.fullPath });

  const handleChangeDateRange = (date: Date) => {
    navigate({
      params: {},
      search: (prev) => ({
        ...prev,
        date: format(date, "yyyy-MM-dd"),
      }),
    });
  };

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-xl font-bold">{loaderData.company.name}</h1>
        <div>
          <SelectMonthDropdown date={new Date(search.date)} onSelect={handleChangeDateRange} />
        </div>
      </div>
      <StatsWidget metrics={loaderData.metrics} />
      <SpendingsWidget data={loaderData.sequences} acc={loaderData.acc} />
    </>
  );
};
