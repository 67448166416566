import { useEffect } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { CreateEmployee } from "@elton/types";

interface IProps {
  data: CreateEmployee;
  onSubmitCreate: (value: Omit<CreateEmployee, "id">) => void;
  onSubmitUpdate: (value: CreateEmployee) => void;
}

export const useEmployeeForm = ({ data, onSubmitCreate, onSubmitUpdate }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      const { phoneNumber, ...otherValues } = values.value;
      const sanitizedUserData: CreateEmployee = {
        ...otherValues,
        phoneNumber: phoneNumber.replace(/\s+/g, ""),
      };

      return data.id ? onSubmitUpdate(sanitizedUserData) : onSubmitCreate(sanitizedUserData);
    },
  });

  useEffect(() => {
    form.setFieldValue("id", data.id);
    form.setFieldValue("name", data.name);
    form.setFieldValue("phoneNumber", data.phoneNumber);
    form.setFieldValue("reference", data.reference);
    form.setFieldValue("registrationNumber", data.registrationNumber);
    form.setFieldValue("defaultNote", data.defaultNote);
    form.setFieldValue("departmentId", data.departmentId);
  }, [data, form]);

  return form;
};
