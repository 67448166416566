import { Account } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const createAccount = async (companyId: string, data: Account) => {
  const res = await getServicesClient().post(`/companies/${companyId}/accounts`, {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
  });

  return res.data;
};
