import { CreateChildCompany, CreateCompanyAndAdmin } from "../../types";

export const defaultCreateCompany: CreateCompanyAndAdmin = {
  name: "",
  organizationNumber: "",
  address: "",
  postalCode: "",
  city: "",
  country: "Norge",
  contactName: "",
  contactPhone: "",
  invoiceEmail: "",
  discountPercentage: 0,
  contactEmail: "",
  invoicingReferences: undefined,
  canBeParent: false,
  adminFirstName: "",
  adminLastName: "",
  adminEmail: "",
};

export const defaultCreateChildCompany: CreateChildCompany = {
  name: "",
  organizationNumber: "",
  address: "",
  postalCode: "",
  city: "",
  country: "Norge",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
};
