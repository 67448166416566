import { Link } from "@tanstack/react-router";

import { cn } from "@elton/ui/lib/utils";

import { CreateDepartmentButton } from "./components/create-department-link";
import { DataPoint } from "./components/data-point";
import { ProgressBar } from "./components/progress-bar";
import { ClassNames } from "./layout";

import { T, useT } from "@transifex/react";

interface IProps {
  className?: string;
  companyId: string;
  total: number;
  max: number;
}

export const EmployeesWidget: React.FC<IProps> = ({ className, companyId, total, max }) => {
  const t = useT();
  const hasReachedMax = total >= max;

  return (
    <div className={cn(ClassNames.Card, className)}>
      <div className="flex justify-between">
        <h3 className="text-md font-medium">
          <T _str="Employees" />
        </h3>
        <div>
          <CreateDepartmentButton disabled={hasReachedMax}>
            <Link
              className="text-sm hover:opacity-50 transition-opacity"
              to="/companies/$companyId/employees"
              params={{ companyId: companyId! }}
              search={{
                offset: 0,
                limit: 10,
                sort: "",
                filter: "",
              }}
            >
              <T _str="Create employee" />
            </Link>
          </CreateDepartmentButton>
        </div>
      </div>

      <div className="flex justify-around">
        <DataPoint label={t("Total employees")} value={total} />
        <DataPoint label={t("Max employees")} value={max} />
      </div>

      <ProgressBar total={total} max={max} />
    </div>
  );
};
