import { List, ListCompany } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client.ts";
import { DefaultListParams } from "@/api/params/default-list-params.ts";

export const getChildCompanies = async (parentCompanyId: string, params?: DefaultListParams) => {
  const res = await getServicesClient().get<List<ListCompany>>(
    `/companies/${parentCompanyId}/companies`,
    { params },
  );

  return res.data;
};
