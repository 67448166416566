import { Link } from "@tanstack/react-router";
import { HeaderContext } from "@tanstack/react-table";

import { SortButton } from "@/components/buttons/sort-button";

export const SortableColumn =
  <T,>(title: string, className?: string) =>
  (props: HeaderContext<T, unknown>) => {
    const state = props.table.getState();
    const currentSorting = state.sorting[0]; // Only allow sorting by one column per time

    const currentColumnSortOrder =
      currentSorting?.id === props.column.id ? (currentSorting.desc ? "desc" : "asc") : undefined;

    const resolveNextSorting = () => {
      if (currentSorting?.id === props.column.id) {
        if (currentSorting.desc) {
          return currentSorting.id;
        }
        return `-${currentSorting.id}`;
      }

      return props.column.id;
    };

    return (
      <Link
        to=""
        search={(prev) => ({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(prev as any),
          sort: resolveNextSorting(),
        })}
        className={className}
      >
        <SortButton variant={currentColumnSortOrder}>{title}</SortButton>
      </Link>
    );
  };

export const MediumColumn = ({ title }: { title: string }) => (
  <span className="font-medium">{title}</span>
);

export const AlignRightColumn = ({ title }: { title: string }) => (
  <span className="flex w-fit ml-auto">{title}</span>
);
