import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@ui/lib/utils";

const alertVariants = cva(
  cn(
    "relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
    "flex gap-2 text-alert",
  ),
  {
    variants: {
      variant: {
        info: "bg-info border-info-foreground",
        success: "bg-success border-success-foreground",
        warning: "bg-warning border-warning-foreground",
        error: "bg-error border-error-foreground",
      },
    },
    defaultVariants: {
      variant: "info",
    },
  },
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
));
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h5
      ref={ref}
      className={cn("mb-1 font-medium leading-none tracking-tight", className)}
      {...props}
    />
  ),
);
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("text-sm [&_p]:leading-relaxed", className)} {...props} />
));
AlertDescription.displayName = "AlertDescription";

const alertIconVariants = cva("", {
  variants: {
    variant: {
      info: "text-[#3F84E5]",
      success: "text-[#1D9D4C]",
      warning: "text-[#BFA14D]",
      error: "text-[#D32036]",
    },
    layout: {
      sm: "w-6 h-6",
      md: "w-4 h-4 md:w-6 md:h-6",
    },
  },
  defaultVariants: {
    layout: "sm",
    variant: "info",
  },
});

const AlertIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertIconVariants>
>(({ variant = "info", className, ...props }, ref) => (
  <div ref={ref} {...props}>
    <svg
      className={cn(alertIconVariants({ variant }), className)}
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      {(variant === "info" || variant === "success") && (
        <path
          d="M12 24.5C15.1826 24.5 18.2348 23.2357 20.4853 20.9853C22.7357 18.7348 24 15.6826 24 12.5C24 9.3174 22.7357 6.26516 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5C8.8174 0.5 5.76516 1.76428 3.51472 4.01472C1.26428 6.26516 0 9.3174 0 12.5C0 15.6826 1.26428 18.7348 3.51472 20.9853C5.76516 23.2357 8.8174 24.5 12 24.5ZM10.125 16.25H11.25V13.25H10.125C9.50156 13.25 9 12.7484 9 12.125C9 11.5016 9.50156 11 10.125 11H12.375C12.9984 11 13.5 11.5016 13.5 12.125V16.25H13.875C14.4984 16.25 15 16.7516 15 17.375C15 17.9984 14.4984 18.5 13.875 18.5H10.125C9.50156 18.5 9 17.9984 9 17.375C9 16.7516 9.50156 16.25 10.125 16.25ZM12 6.5C12.3978 6.5 12.7794 6.65804 13.0607 6.93934C13.342 7.22064 13.5 7.60218 13.5 8C13.5 8.39782 13.342 8.77936 13.0607 9.06066C12.7794 9.34196 12.3978 9.5 12 9.5C11.6022 9.5 11.2206 9.34196 10.9393 9.06066C10.658 8.77936 10.5 8.39782 10.5 8C10.5 7.60218 10.658 7.22064 10.9393 6.93934C11.2206 6.65804 11.6022 6.5 12 6.5Z"
          fill="currentColor"
        />
      )}
      {variant === "error" && (
        <path
          d="M12 24.5C15.1826 24.5 18.2348 23.2357 20.4853 20.9853C22.7357 18.7348 24 15.6826 24 12.5C24 9.3174 22.7357 6.26516 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5C8.8174 0.5 5.76516 1.76428 3.51472 4.01472C1.26428 6.26516 0 9.3174 0 12.5C0 15.6826 1.26428 18.7348 3.51472 20.9853C5.76516 23.2357 8.8174 24.5 12 24.5ZM12 6.5C12.6234 6.5 13.125 7.00156 13.125 7.625V12.875C13.125 13.4984 12.6234 14 12 14C11.3766 14 10.875 13.4984 10.875 12.875V7.625C10.875 7.00156 11.3766 6.5 12 6.5ZM10.5 17C10.5 16.6022 10.658 16.2206 10.9393 15.9393C11.2206 15.658 11.6022 15.5 12 15.5C12.3978 15.5 12.7794 15.658 13.0607 15.9393C13.342 16.2206 13.5 16.6022 13.5 17C13.5 17.3978 13.342 17.7794 13.0607 18.0607C12.7794 18.342 12.3978 18.5 12 18.5C11.6022 18.5 11.2206 18.342 10.9393 18.0607C10.658 17.7794 10.5 17.3978 10.5 17Z"
          fill="currentColor"
        />
      )}
      {/* Warning */}
      {variant === "warning" && (
        <path
          d="M11.9991 -0.000976562C12.6648 -0.000976562 13.2789 0.350611 13.6164 0.927215L23.7422 18.1784C24.0844 18.7597 24.0844 19.477 23.7515 20.0583C23.4187 20.6396 22.7952 21.0005 22.1249 21.0005H1.8734C1.20304 21.0005 0.579561 20.6396 0.246725 20.0583C-0.0861113 19.477 -0.0814235 18.7551 0.256101 18.1784L10.3818 0.927215C10.7193 0.350611 11.3335 -0.000976562 11.9991 -0.000976562ZM11.9991 5.99945C11.3756 5.99945 10.874 6.50105 10.874 7.12453V12.3749C10.874 12.9984 11.3756 13.5 11.9991 13.5C12.6226 13.5 13.1242 12.9984 13.1242 12.3749V7.12453C13.1242 6.50105 12.6226 5.99945 11.9991 5.99945ZM13.4992 16.5002C13.4992 16.1024 13.3412 15.7208 13.0599 15.4395C12.7785 15.1581 12.397 15.0001 11.9991 15.0001C11.6013 15.0001 11.2197 15.1581 10.9384 15.4395C10.6571 15.7208 10.499 16.1024 10.499 16.5002C10.499 16.8981 10.6571 17.2796 10.9384 17.5609C11.2197 17.8423 11.6013 18.0003 11.9991 18.0003C12.397 18.0003 12.7785 17.8423 13.0599 17.5609C13.3412 17.2796 13.4992 16.8981 13.4992 16.5002Z"
          fill="currentColor"
        />
      )}
    </svg>
  </div>
));
AlertIcon.displayName = "AlertIcon";

export { Alert, AlertTitle, AlertDescription, AlertIcon };

<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12 24.5C15.1826 24.5 18.2348 23.2357 20.4853 20.9853C22.7357 18.7348 24 15.6826 24 12.5C24 9.3174 22.7357 6.26516 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5C8.8174 0.5 5.76516 1.76428 3.51472 4.01472C1.26428 6.26516 0 9.3174 0 12.5C0 15.6826 1.26428 18.7348 3.51472 20.9853C5.76516 23.2357 8.8174 24.5 12 24.5ZM12 6.5C12.6234 6.5 13.125 7.00156 13.125 7.625V12.875C13.125 13.4984 12.6234 14 12 14C11.3766 14 10.875 13.4984 10.875 12.875V7.625C10.875 7.00156 11.3766 6.5 12 6.5ZM10.5 17C10.5 16.6022 10.658 16.2206 10.9393 15.9393C11.2206 15.658 11.6022 15.5 12 15.5C12.3978 15.5 12.7794 15.658 13.0607 15.9393C13.342 16.2206 13.5 16.6022 13.5 17C13.5 17.3978 13.342 17.7794 13.0607 18.0607C12.7794 18.342 12.3978 18.5 12 18.5C11.6022 18.5 11.2206 18.342 10.9393 18.0607C10.658 17.7794 10.5 17.3978 10.5 17Z"
    fill="#D32036"
  />
</svg>;
