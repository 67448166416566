import React from "react";
import { Link } from "@tanstack/react-router";
import { format } from "date-fns";

import { ListCompany } from "@elton/types";
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@elton/ui/core";
import { Check, ChevronsUpDown } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { T, useT } from "@transifex/react";

interface IProps {
  selectedCompany?: ListCompany;
  companies: ListCompany[];
}

export const SelectCompanyDropdown: React.FC<IProps> = ({ selectedCompany, companies }) => {
  const t = useT();
  const [open, setOpen] = React.useState(false);
  const { main, children } = splitMainAndChildren(companies);
  const title = selectedCompany?.name || t("Select company") + "..";

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" className="w-[200px] justify-between">
          <span className="truncate ellipsis">{title}</span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search companies..." />
          <CommandList>
            <CommandEmpty>
              <T _str={t("No company found.")} />
            </CommandEmpty>
            <CompanyCommandGroup
              title={t("Your companies")}
              selectedCompany={selectedCompany}
              companies={main}
              setOpen={setOpen}
            />
            <CompanyCommandGroup
              title={t("Branch companies")}
              selectedCompany={selectedCompany}
              companies={children}
              setOpen={setOpen}
            />
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const CompanyCommandGroup = ({
  title,
  companies,
  selectedCompany,
  setOpen,
}: {
  title: string;
  companies: ListCompany[];
  selectedCompany?: ListCompany;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <CommandGroup heading={title}>
      {companies.map((company) => {
        return (
          <CommandItem key={company.id} asChild>
            <Link
              to="/companies/$companyId/dashboard"
              params={{ companyId: company.id }}
              onClick={() => setOpen(false)}
              search={{ date: format(new Date(), "yyyy-MM-dd") }}
            >
              <Check
                className={cn(
                  "mr-2 h-4 w-4",
                  selectedCompany?.id === company.id ? "opacity-100" : "opacity-0",
                )}
              />
              <span>{company.name}</span>
            </Link>
          </CommandItem>
        );
      })}
    </CommandGroup>
  );
};

function splitMainAndChildren(companies: ListCompany[]): {
  main: ListCompany[];
  children: ListCompany[];
} {
  const children = companies.filter((comp) =>
    companies.some((compAccess) => compAccess.id === comp.parentCompanyId),
  );

  const main = companies.filter((comp) => !children.some((child) => child.id === comp.id));
  return { children, main };
}
