import { Button } from "@elton/ui/core";

import { DateRangePreset } from "./use-date-range-presets";

interface DateRangePresetListProps {
  presets: DateRangePreset[];
  onSelectPreset: (preset: DateRangePreset) => void;
}

const DateRangePresetList: React.FC<DateRangePresetListProps> = ({ presets, onSelectPreset }) => {
  return (
    <ul className="overflow-y-auto">
      {presets.map((preset, i) => (
        <li key={`preset-${i}`}>
          <Button variant="link" onClick={() => onSelectPreset(preset)}>
            {preset.title}
          </Button>
        </li>
      ))}
    </ul>
  );
};

export default DateRangePresetList;
