import { useMemo } from "react";

import { findActiveSidebarNavigationItem } from "./sidebar-navigation-utils";

import { ISidebarNavigationItem } from "@/types/navigation/sidebar-navigation";

/**
 * Returns the active sidebar navigation item based on the current pathname.
 *
 * @param pathname - The current pathname.
 * @param items - The array of sidebar navigation items.
 * @returns The active sidebar navigation item.
 */
export const useActiveSidebarNavigation = (pathname: string, items: ISidebarNavigationItem[]) => {
  return useMemo(() => findActiveSidebarNavigationItem(pathname || "", items), [items, pathname]);
};
