import { Company } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";
import { EltonRoleStoreKey } from "@/context/role-context/role-context";

export const getCompany = async (companyId: string) => {
  const role = localStorage.getItem(EltonRoleStoreKey);

  const url = role === "superadmin" ? `/companies/${companyId}/full` : `/companies/${companyId}`;

  const res = await getServicesClient().get<Company>(url);

  return res.data;
};
