import { T } from "@transifex/react";

interface IProps {
  index: number;
  count: number;
}

export const TableInfo: React.FC<IProps> = ({ index, count }) => {
  return (
    <div className="flex-1 text-sm text-muted-foreground">
      <T _str="Showing page {index} of {count} page(s)." index={index} count={count} />
    </div>
  );
};
